<template>
  <!-- Global Toast y ConfirmPopup en el home para que puedan ser usados en cualquier parte de la aplicacion -->
  <Toast />
  <ConfirmPopup></ConfirmPopup>
  <div id="layout-wrapper">
    <Sidemenu></Sidemenu>
    <div class="layout page-content">
      <div class="layout-main-container">
        <Topbar></Topbar>
      <div class="layout-main">
        <router-view />
      </div>
      <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '../components/Sidebar'
import Sidemenu from '../components/Sidemenu'
import Topbar from '../components/navbar/Topbar.vue'
import Footer from '../components/Footer'
import Toast from 'primevue/toast'
import ConfirmPopup from 'primevue/confirmpopup'

export default {
  name: 'Home',
  components: { Sidebar, Topbar, Footer, Toast, ConfirmPopup, Sidemenu },
}
</script>
