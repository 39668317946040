<template>
  <div class="row">
    <div class="col-12">
      <DataTable
        :value="closedDeals"
        responsiveLayout="scroll"
        :paginator="true"
        :alwaysShowPaginator="false"
        :rows="numRows"
        :resizableColumns="true"
        columnResizeMode="expand"
        removableSort
        dataKey="id"
        v-model:filters="filters"
        :loading="loading"
        :empty="empty"
        ref="dt"
      >
        <template #header>
          <div class="row justify-content-between">
            <div class="col-3">
              <div class="d-flex align-items-center">
                <p class="text-start m-0 me-3">Show</p>
                <select class="form-select" v-model="numRows" style="width: 80px">
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="50">50</option>
                </select>
              </div>
            </div>
            <div class="col-4">
              <input type="text" class="form-control" placeholder="Search" v-model="filters['global'].value" />
            </div>
          </div>
        </template>
        <Column :sortable="true" field="property.name" header="Property"></Column>
        <Column field="agent" header="Agent">
          <template #body="slotProps">
            {{ slotProps.data.agent.name }}
            {{ slotProps.data.agent.last_name }}
          </template>
        </Column>
        <Column :sortable="true" field="agent.team.name" header="Team"></Column>
        <Column :sortable="true" field="client.name" header="Client">
          <template #body="slotProps">
            {{ slotProps.data.client.name }}
            {{ slotProps.data.client.last_name }}
          </template>
        </Column>
        <Column :sortable="true" header="Sale Price">
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.sale_price) }}
          </template>
        </Column>
        <Column :sortable="true" field="deal_source.name" header="Source"></Column>
        <Column :sortable="true" field="created_at" header="Created at">
          <template #body="{ data }">
            {{ formatDate(data.created_at) }}
          </template>
        </Column>
        <Column header="Actions">
          <template #body="slotProps">
            <div class="d-flex">
              <button class="btn" @click="editDeal($event, slotProps.data)"><i class="pi pi-fw pi-pencil"></i></button>
              <button class="btn" @click="(e) => deleteDeal(e, slotProps.data)"><i class="pi pi-trash"></i></button>
            </div>
          </template>
        </Column>
        <template #footer>
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary" @click="exportCSV">Export CSV</button>
          </div>
        </template>
        <template #loading> <p class="text-center">Loading records, please wait...</p> </template>
        <template #empty> <p class="text-center">No records to display</p> </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref } from 'vue'
import { watch } from '@vue/runtime-core'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import { useDatatable } from '@/hooks/useDatatable'

export default {
  components: {
    DataTable,
    Column,
  },
  props: {
    closedDeals: { required: true },
    loading: { required: true },
  },
  setup(props, { emit }) {
    const store = useStore()
    const toast = useToast()
    const confirm = useConfirm()
    const loading = ref(props.loading)
    const { filters, dt, numRows, empty } = useDatatable()

    const closedDeals = ref(props.closedDeals)

    const exportCSV = () => {
      dt.value.exportCSV()
    }

    const formatDate = (date) => {
      if (!date) return ''
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      return new Date(date).toLocaleDateString('en-US', options)
    }

    const editDeal = (e, deal) => {
      emit('editDeal', deal)
    }

    function deleteDeal(event, deal) {
      confirm.require({
        target: event.currentTarget,
        message: 'Do you want to delete this record?',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
          store.dispatch('deals/deleteDeal', deal)
          toast.add({ severity: 'success', summary: 'Deleted', detail: `Deal has been deleted`, life: 3000 })
        },
      })
    }

    const formatCurrency = (amount) => {
      const formated = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }).format(amount)
      return formated
    }

    watch(props, () => {
      closedDeals.value = props.closedDeals
      if (closedDeals.value.length === 0) {
        empty.value = true
      }
      loading.value = props.loading
    })

    return {
      numRows,
      loading,
      dt,
      filters,
      empty,
      closedDeals,
      formatDate,
      exportCSV,
      editDeal,
      deleteDeal,
      formatCurrency,
    }
  },
}
</script>
