import axios from '@/config/axios'

class EscrowAgencyService {
  async fetchAll() {
    try {
      const resp = await axios.get('/escrow_agency')
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchAgency(id) {
    try {
      const resp = await axios.get(`/escrow_agency/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async createAgency({ name }) {
    try {
      const resp = await axios.post('/escrow_agency', { name })
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async updateAgency(id, { name }) {
    try {
      const resp = await axios.put(`/escrow_agency/${id}`, { name })
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async deleteAgency(id) {
    try {
      const resp = await axios.delete(`/escrow_agency/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }
}

export default new EscrowAgencyService()
