<template>
  <div class="card card-body d-block">
    <p class="fw-bold m-0">Dates</p>
    <div class="row justify-content-center align-items-center">
      <div class="col-5">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="date"
            id="created_at"
            value="created_at"
            v-model="filter"
          />
          <label class="form-check-label" for="created_at"> Created Date </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="date" id="aeo" value="aeo" v-model="filter" />
          <label class="form-check-label" for="aeo"> AEO </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="date" id="coe" value="coe" v-model="filter" />
          <label class="form-check-label" for="coe"> COE </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="date" id="ext" value="ext" v-model="filter" />
          <label class="form-check-label" for="ext"> EXT </label>
        </div>
      </div>
      <div class="col-3">
        <div class="d-flex" v-if="enabled">
          <p class="m-auto me-3 fw-bold">Year:</p>
          <select name="year" class="form-control" v-model="year">
            <option v-for="year in selectYears" :key="year" :value="year">{{ year }}</option>
          </select>
        </div>
      </div>
      <div class="col-2">
        <button class="btn btn-primary" @click="filter = null" v-if="enabled">Clear</button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const selectYears = [2020, 2021, 2022]
    const filter = computed({
      get: () => store.state.deals.filter,
      set: (value) => store.commit('deals/setFilter', value),
    })
    const year = computed({
      get: () => store.state.deals.year,
      set: (value) => store.commit('deals/setYear', value),
    })
    const enabled = computed(() => filter.value != null)

    function fillYears() {
      //TODO endpoint para obtener los años limite de manera dinamica
    }

    return { filter, selectYears, year, enabled }
  },
}
</script>

<style></style>
