import axios from '@/config/axios'

class SourceService {
  async fetchAll() {
    try {
      const resp = await axios.get('/deal_source')
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchSource(id) {
    try {
      const resp = await axios.get(`/deal_source/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async createSource({ name }) {
    try {
      const resp = await axios.post('/deal_source', { name })
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async updateSource(id, { name }) {
    try {
      const resp = await axios.put(`/deal_source/${id}`, { name })
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async deleteSource(id) {
    try {
      const resp = await axios.delete(`/deal_source/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }
}

export default new SourceService()
