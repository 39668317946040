<template>
  <Toast />
  <!-- <div class="login">
    <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="3000">
          <img
            src="https://realestatemarket.com.mx/images/2020/09-Septiembre/2809/inversion_en_bienes_raices_piensa_en_numeros_no_en_fachadas_1.jpg"
            class="d-block w-100"
            alt="..."
          />
        </div>
        <div class="carousel-item" data-bs-interval="2000">
          <img
            src="https://blog.uplace.mx/hubfs/Postedin-Blog-Images/san-pedro-de-los-pinos-una.png"
            class="d-block w-100"
            alt="..."
          />
        </div>
        <div class="carousel-item">
          <img
            src="https://img10.naventcdn.com/avisos/18/00/60/66/37/48/720x532/249713939.jpg"
            class="d-block w-100"
            alt="..."
          />
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleInterval"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleInterval"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    <div class="overlay d-flex justify-content-between pt-5">
      <div class="row justify-content-center w-100">
        <div class="col-lg-4 col-md-8 col-sm-12">
          <div class="login-card">
            <div class="login-header text-center">
              <img src="img/logo_boardwalk.png" class="w-75" alt="" />

              <h3 class="white my-3">Login to Boardwalk Internal</h3>
              <hr />
            </div>
            <form @submit.prevent="() => {}">
              <div class="login-body flex-start">
                <div class="form-group">
                  <label for="" class="form-label">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    :class="v.email.$error ? 'is-invalid' : ''"
                    v-model="v.email.$model"
                  />
                  <p class="invalid-feedback d-block m-0">{{ v.email.$errors[0]?.$message }}</p>
                </div>
                <div class="form-group">
                  <label for="" class="label-control">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    :class="v.password.$error ? 'is-invalid' : ''"
                    v-model="v.password.$model"
                  />
                  <p class="invalid-feedback d-block m-0">{{ v.password.$errors[0]?.$message }}</p>
                </div>
                <div class="mt-4 d-flex align-items-center flex-column">
                  <button type="submit" class="btn btn-danger w-50 mb-2" @click="login()">Login</button>
                  <span>Forgot your password? <a href="#">Click here</a></span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div> -->
    <div>
    <div class="home-btn d-none d-sm-block">
      <!-- <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a> -->
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a class="logo">
                            <img src="img/logo_boardwalk.png" height="80" alt="logo" />
                          </a>
                        </div>
                        <h4 class="font-size-18 mt-4">Welcome!</h4>
                        <p class="text-muted">Sign in to continue to The Boardwalk Proyect.</p>
                      </div>
                      <div class="p-2 mt-5">
                        <form @submit.prevent="() => {}" class="form-horizontal">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">Email</label>
                            <input
                              type="email"
                              class="form-control"
                              placeholder="Enter email"
                              :class="v.email.$error ? 'is-invalid' : ''"
                              v-model="v.email.$model"
                            />
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">Password</label>
                            <input
                              type="password"
                              class="form-control"
                              placeholder="Enter password"
                              :class="v.password.$error ? 'is-invalid' : ''"
                              v-model="v.password.$model"
                            />
                          </div>
                          <p class="invalid-feedback d-block m-0">{{ v.password.$errors[0]?.$message }}</p>
                          <div class="mt-4 text-center">
                            <button 
                              class="btn btn-primary w-md waves-effect waves-light" 
                              type="submit"
                              @click="login()"
                            >Log In</button>
                          </div>

                          <!-- <div class="mt-4 text-center">
                            <router-link tag="a" to="/forgot-password" class="text-muted">
                              <i class="mdi mdi-lock mr-1"></i> Forgot your password?
                            </router-link>
                          </div> -->
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          © 2022 Boardwalk.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'remixicon/fonts/remixicon.css'
import '../assets/scss/custom/plugins/_auth.scss'

import { useStore } from "vuex"
import { useRouter } from 'vue-router'
import { ref, reactive, computed, onMounted } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'

export default {
  components:{
    Toast
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = reactive({email: '', password: ''})
    const toast = useToast()

    onMounted(()=>{
      store.dispatch('deleteCredentials')
    })

    const login = async () => {
      const isValid = await v.value.$validate()
      if (!isValid) return
      try {
        const response = await store.dispatch("login", user)
        if(response.token){
          toast.add({ severity: 'success', summary: 'Logged with success!', detail: '', life: 3000 })
          store.dispatch('fetchStates')
          store.dispatch('fetchDealStatus')
          store.dispatch('fetchSources')
          store.dispatch('fetchTeams')
          router.push('/');
        }else{
          toast.add({ severity: 'error', summary: 'Error', detail: 'Email or password are not correct', life: 3000 })
        }
      } catch (error) {
        console.log(error)
        toast.add({ severity: 'error', summary: 'Error', detail: 'Email or password are not correct', life: 3000 })
      }
    }

    const rules = computed(() => ({
      email: { required },
      password: { required },
    }))

    const v = useVuelidate(rules, user)   

    return {
      user,
      v,
      login,
    }
  },
}
</script>
