import axios from '@/config/axios'

class AgentService {
  async fetchAll() {
    try {
      const resp = await axios.get('/agents')
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchAgent(id) {
    try {
      const resp = await axios.get(`/agents/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }
}

export default new AgentService()
