<template>
  <div class="dropdown w-100">
    <button class="form-control dropdown-toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <div>{{ selectedNotary.name ? selectedNotary.name : 'Select a Notary' }}</div>
      <i class="fas fa-chevron-down"></i>
    </button>
    <div class="dropdown-menu w-100 custom-shadow" aria-labelledby="dropdownMenuButton1">
      <div class="w-100 p-2">
        <input type="text" class="form-control" placeholder="Search notary..." @keyup="filterNotaries($event)">
      </div>
      <div class="dropdown-item-list">
        <div class="dropdown-item" v-for="(notary, index) in filteredNotaries" :key="index" @click="selectNotary(index)">
          <i class="fas fa-university"></i>
          <span>{{ notary.name }} </span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import { ref, onMounted } from  'vue'

  import NotaryService from '../../services/notary.service'
  export default {
    props: ['notary'],
    emits: ['selectNotary'],
    setup(props, {emit}) {

      var notaries = ref([])
      var filteredNotaries = ref([])
      var selectedNotary = ref(props.notary)

      onMounted(() => {
        getNotaries()
      })

      const getNotaries = async () => {
        notaries.value = await NotaryService.fetchAll()
        filteredNotaries.value = notaries.value
      }

      const filterNotaries = (e) => {
        let filtered = notaries.value.filter((notary) => {
          if(notary.name.toUpperCase().includes(e.target.value.toUpperCase())){
            return notary;
          }
        })
        filteredNotaries.value = filtered
      }

      const selectNotary = (index) => {
        emit('selectNotary', filteredNotaries.value[index])
        selectedNotary.value = filteredNotaries.value[index]
      }

      return{
        filteredNotaries,
        selectedNotary,
        selectNotary,
        filterNotaries,
      }
    },
  }
</script>

<style lang="scss">
.dropdown-toggle.form-control{
  min-width: 220px;
}
  .dropdown-item-list{
    max-height: 200px;
    overflow-y: auto;
    .dropdown-item{
      display: flex;
      align-items: center;
      white-space: initial;
      line-height: initial;
      cursor: pointer;
      padding: 0.5rem 1rem;
      transition: .3s;
      &:hover{
        background-color: aliceblue;
      }
      
      i{
        margin-right: 15px;
      }
    }
  }
</style>