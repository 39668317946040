import axios from '@/config/axios'

class ClientService {
  async fetchAll(role_id) {
    try {
      const resp = await axios.get(`/clients?role=${role_id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchClient(id) {
    try {
      const resp = await axios.get(`/clients/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async createClient({ name, last_name, email, phone, address, city, state, country }) {
    try {
      const resp = await axios.post('/clients', { name, last_name, email, phone, address, city, state, country })
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async updateClient(id, { name, last_name, email, phone, address, city, state, country }) {
    try {
      const resp = await axios.put(`/clients/${id}`, { name, last_name, email, phone, address, city, state, country })
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async deleteClient(id) {
    try {
      const resp = await axios.delete(`/clients/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }
}

export default new ClientService()
