import DealsService from '../services/deal.service'

export default {
  namespaced: true,
  state: {
    deals: [],
    filter: 'created_at',
    year: 2020,
  },
  mutations: {
    setDeals(state, deals) {
      state.deals = deals
    },
    setFilter(state, filter) {
      state.filter = filter
    },
    setYear(state, year) {
      state.year = year
    },
    deleteDeal(state, deal) {
      state.deals = state.deals.filter((d) => d.id != deal.id)
    },
  },
  actions: {
    async fetchDeals({ rootState, commit }) {
      let deals = []
      if (rootState.selectedRole.name === 'Admin' || rootState.selectedRole.name === 'Office Manager') {
        deals = await DealsService.fetchAllDeals()
      } else if (rootState.selectedTeamRole.name == 'Leader') {
        deals = await DealsService.fetchTeamDeals(rootState.user.user.team_id)
      } else {
        deals = await DealsService.fetchAgentDeals(rootState.user.user.id)
      }
      commit('setDeals', deals)
    },
    async deleteDeal({ commit }, deal) {
      try {
        await DealsService.deleteDeal(deal.id)
        commit('deleteDeal', deal)
      } catch (error) {
        console.log(error)
      }
    },
  },
  getters: {
    filteredDeals: (state) => {
      if (state.filter == null) return state.deals
      else if (state.filter == 'created_at')
        return state.deals.filter((d) => d.created_at.substring(0, 4) == state.year)
      else if (state.filter == 'aeo') return state.deals.filter((d) => (d.aeo === null ? "" : d.aeo.substring(0, 4)) == state.year)
      else if (state.filter == 'coe') return state.deals.filter((d) => (d.coe === null ? "" : d.coe.substring(0, 4)) == state.year)
      else if (state.filter == 'ext') return state.deals.filter((d) => (d.ext === null ? "" : d.ext.substring(0, 4)) == state.year)
    },
  },
}
