import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL || 'http://localhost:8000/api'
axios.defaults.headers.common['Accept'] = 'application/json'

if (localStorage.getItem('user')) {
  const user = JSON.parse(localStorage.getItem('user'))
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token
}

export default axios
