import axios from '@/config/axios'
import downloadjs from 'downloadjs'

class DealService {
  async fetchSaleTypes() {
    try {
      const resp = await axios.get('/sale_type')
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchDealStatus() {
    try {
      const resp = await axios.get('/deal_status')
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchAllDeals() {
    try {
      const resp = await axios.get('/deals')
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchTeamDeals(team_id) {
    try {
      const resp = await axios.get(`/deals/team/${team_id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchAgentDeals(agent_id) {
    try {
      const resp = await axios.get(`/deals/agent/${agent_id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchDeal(id) {
    try {
      const resp = await axios.get(`/deals/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async createDeal(deal) {
    try {
      const resp = await axios.post('/deals', deal)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async updateDeal(id, deal) {
    try {
      const resp = await axios.put(`/deals/${id}`, deal)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async deleteDeal(id) {
    try {
      const resp = await axios.delete(`/deals/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async downloadDealPdf(deal) {
    try {
      const response = await axios.get(`/reports/deal/${deal.id}`, { responseType: 'blob' })
      downloadjs(response.data, `${deal.property.name}.pdf`, response.data.type)
    } catch (error) {
      throw error.response
    }
  }

  async fetchDealStats(role_id, team_role_id, year, field) {
    try {
      const response = await axios.post('/deals/stats', {role_id, team_role_id, year, field})
      return response.data
    } catch (error) {
      console.log(error.response)
      throw error.response
    }
  }

  calculateDealValues({
    salePrice,
    brPercentage,
    teamPercentage = 70,
    closingCordPercentage = 0,
    agentSplitPercentage = 70,
    referrals,
    assistants,
  }) {
    const brMoney = (salePrice * brPercentage) / 100
    //sumar referrals
    let totalRefPercentage = 0
    referrals.forEach((r) => (totalRefPercentage += r.fee_percentage))

    const refFeeTotal = (brMoney * totalRefPercentage) / 100
    const leftToSplit_1 = brMoney - refFeeTotal
    const teamMoney = leftToSplit_1 * (teamPercentage / 100)
    const leftToBr = leftToSplit_1 - teamMoney
    const closingCordMoney = (closingCordPercentage / 100) * teamMoney
    const leftToSplit_2 = teamMoney - closingCordMoney
    const agentSplitMoney = leftToSplit_2 * (agentSplitPercentage / 100)

    //sumar assistants
    let totalAssistantPercentage = 0
    assistants.forEach((a) => (totalAssistantPercentage += a.fee_percentage))

    const assistantsFeeTotal = (totalAssistantPercentage / 100) * leftToSplit_2
    const leftToSplit_3 = leftToSplit_2 - agentSplitMoney
    const head = leftToSplit_3 - assistantsFeeTotal

    return {
      br_money: round(brMoney),
      ref_fee_total: round(refFeeTotal),
      left_to_split_1: round(leftToSplit_1),
      team_money: round(teamMoney),
      left_to_br: round(leftToBr),
      closing_cord_money: round(closingCordMoney),
      closing_cord_percentage: closingCordPercentage,
      left_to_split_2: round(leftToSplit_2),
      agent_split_money: round(agentSplitMoney),
      assistants_fee_total: round(assistantsFeeTotal),
      left_to_split_3: round(leftToSplit_3),
      head: round(head),
    }
  }
}

function round(num) {
  return +(Math.round(num + 'e+2') + 'e-2')
}

export default new DealService()
