import axios from '@/config/axios'

class TeamService {
  async fetchAll() {
    try {
      const resp = await axios.get('/teams')
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchTeam(id) {
    try {
      const resp = await axios.get(`/teams/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async createTeam({ name }) {
    try {
      const resp = await axios.post('/teams', { name })
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async updateTeam(id, { name }) {
    try {
      const resp = await axios.put(`/teams/${id}`, { name })
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async deleteTeam(id) {
    try {
      const resp = await axios.delete(`/teams/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }
}

export default new TeamService()
