<template>
  <div class="dropdown dropend">
    <button
      class="btn btn-info dropdown-toggle"
      :class="{ show: showDropdown }"
      type="button"
      id="dropdownMenuNewClient"
      aria-expanded="false"
      data-bs-reference="parent"
      data-bs-auto-close="false"
      @click="toggleDropdown()"
    >
      <i class="fas fa-plus me-2"></i>
      <div>New</div>
    </button>
    <div
      class="dropdown-menu w-500 custom-shadow mt-1 py-3 px-1"
      :class="{ show: showDropdown }"
      aria-labelledby="dropdownMenuNewClient"
    >
      <form class="row" @submit.prevent="() => {}">
        <div class="col-12 text-center">
          <h5>New Client</h5>
          <hr />
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="">Name</label>
            <input
              type="text"
              class="form-control"
              :class="v.name.$error ? 'is-invalid' : ''"
              v-model="v.name.$model"
            />
            <p class="invalid-feedback d-block m-0">{{ v.name.$errors[0]?.$message }}</p>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="">Last Name</label>
            <input type="text" class="form-control" v-model="clientForm.last_name" />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Email</label>
            <input
              type="text"
              class="form-control"
              :class="v.email.$error ? 'is-invalid' : ''"
              v-model="v.email.$model"
            />
            <p class="invalid-feedback d-block m-0">{{ v.email.$errors[0]?.$message }}</p>
          </div>
        </div>
        <div class="col-12">
          <hr />
        </div>
        <!-- <div class="col-12">
          <div class="form-group">
            <label for="">Address</label>
            <input type="text" class="form-control" v-model="clientForm.address">
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">City</label>
            <input type="text" class="form-control" v-model="clientForm.city">
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="">State</label>
            <input type="text" class="form-control" v-model="clientForm.state">
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="">Country</label>
            <input type="text" class="form-control" v-model="clientForm.country">
          </div>
        </div>
        <div class="col-12">
          <hr>
        </div> -->
        <div class="col-12 text-center">
          <button class="btn btn-secondary me-2" @click="showDropdown = false">Cancel</button>
          <button class="btn btn-primary" @click="saveClient()">Create Client</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, onMounted, reactive, computed } from 'vue'

import ClientService from '../../services/client.service'
import { required, email } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
export default {
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const showDropdown = ref(false)

    const clientForm = reactive({
      name: '',
      last_name: '',
      email: '',
      address: '',
      city: '',
      state: '',
      country: '',
    })
    const rules = computed(() => ({
      name: { required },
      email: { required },
    }))
    const v = useVuelidate(rules, clientForm, { $scope: false })

    const reset = () => {
      clientForm.name = ''
      clientForm.last_name = ''
      clientForm.email = ''
      clientForm.address = ''
      clientForm.city = ''
      clientForm.state = ''
      clientForm.country = ''
      v.value.$reset()
    }

    const saveClient = async () => {
      const isValid = await v.value.$validate()
      if (!isValid) return

      try {
        const response = await ClientService.createClient(clientForm)
        reset()
        showDropdown.value = false
        emit('getNewClient', response)
      } catch (error) {
        console.log(error)
        emit('error', 'Client')
      }
    }

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value
    }

    return {
      clientForm,
      v,
      showDropdown,
      saveClient,
      toggleDropdown,
    }
  },
}
</script>
