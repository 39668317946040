<template>
  <div class="dropdown w-100">
    <button class="form-control dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
      <div>{{ selectedAgent.name ? selectedAgent.name + ' ' + selectedAgent.last_name : 'Select a Agent' }}</div>
      <i class="fas fa-chevron-down"></i>
    </button>
    <div class="dropdown-menu w-100 custom-shadow" aria-labelledby="dropdownMenuButton1">
      <div class="w-100 p-2">
        <input type="text" class="form-control" placeholder="Search agent..." @keyup="filterAgents($event)">
      </div>
      <div class="dropdown-item-list">
        <div class="dropdown-item" v-for="(agent, index) in filteredAgents" :key="index" @click="selectAgent(index)">
          <i class="fas fa-university"></i>
          <span>{{ agent.name }} {{ agent.last_name }} </span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import { ref, onMounted, watch } from  'vue'

  import AgentService from '../../services/agent.service'
  export default {
    props: ['agent'],
    emits: ['selectAgent'],
    setup(props, {emit}) {

      var agents = ref([])
      var filteredAgents = ref([])
      var selectedAgent = ref(props.agent)

      onMounted(() => {
        getAgents()
      })

      const getAgents = async () => {
        agents.value = await AgentService.fetchAll()
        filteredAgents.value = agents.value
      }

      const filterAgents = (e) => {
        let filtered = agents.value.filter((agent) => {
          if(agent.name.toUpperCase().includes(e.target.value.toUpperCase())){
            return agent;
          }
        })
        filteredAgents.value = filtered
      }

      const selectAgent = (index) => {
        emit('selectAgent', filteredAgents.value[index])
        selectedAgent.value = filteredAgents.value[index]
      }

      return{
        filteredAgents,
        selectedAgent,
        selectAgent,
        filterAgents,
      }
    },
  }
</script>

<style lang="scss">
.dropdown-toggle.form-control{
  min-width: 220px;
}
  .dropdown-item-list{
    max-height: 200px;
    overflow-y: auto;
    .dropdown-item{
      display: flex;
      align-items: center;
      white-space: initial;
      line-height: initial;
      cursor: pointer;
      padding: 0.5rem 1rem;
      transition: .3s;
      &:hover{
        background-color: aliceblue;
      }
      
      i{
        margin-right: 15px;
      }
    }
  }
</style>