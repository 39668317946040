import axios from '@/config/axios'

class AuthService {
  async login(user) {
    try {
      const resp = await axios.post('/login', user)
      axios.defaults.headers.common['Authorization'] = `Bearer ${resp.data.token}`
      return resp
    } catch (error) {
      throw error.response.data
    }
  }

  async logout() {
    try {
      const resp = await axios.get('/logout')
      axios.defaults.headers.common['Authorization'] = null
      return resp
    } catch (error) {
      throw error.response.data
    }
  }

  async getCurrentUser() {
    try {
      const resp = await axios.get('/user')
      return resp.data
    } catch (error) {
      throw error.response.data
    }
  }

  async sendRecoveryPasswordEmail({ email }) {
    try {
      const resp = await axios.post('/password/email', { email })
      return resp.data
    } catch (error) {
      throw error.response.data
    }
  }

  async registerUser({
    name,
    last_name,
    email,
    password,
    password_confirmation,
    phone,
    ld_user_id,
    team_id,
    roles,
    team_roles,
  }) {
    try {
      const resp = await axios.post('/users', {
        name,
        last_name,
        email,
        password,
        password_confirmation,
        phone,
        ld_user_id,
        team_id,
        roles,
        team_roles,
      })
      return resp.data
    } catch (error) {
      throw error.response.data
    }
  }

  async updateUser(
    id,
    { name, last_name, email, password, password_confirmation, phone, ld_user_id, team_id, roles, team_roles }
  ) {
    try {
      const resp = await axios.put(`/users/${id}`, {
        name,
        last_name,
        email,
        password,
        password_confirmation,
        phone,
        ld_user_id,
        team_id,
        roles,
        team_roles,
      })
      return resp.data
    } catch (error) {
      throw error.response.data
    }
  }

  async deleteUser(id) {
    try {
      const resp = await axios.delete(`/users/${id}`)
      return resp.data
    } catch (error) {
      throw error.response.data
    }
  }

  async fetchUsers() {
    try {
      const resp = await axios.get('/users')
      return resp.data
    } catch (error) {
      throw error.response.data
    }
  }

  async fetchRoles() {
    try {
      const resp = await axios.get('/roles')
      return resp.data
    } catch (error) {
      throw error.response.data
    }
  }

  async adminResetPassword(id, { password, password_confirmation }) {
    try {
      const resp = await axios.put(`/users/admin/reset/${id}`, {
        password,
        password_confirmation,
      })
      return resp.data
    } catch (error) {
      throw error.response.data
    }
  }
}

export default new AuthService()
