<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          2022 © Boardwalk Realty PV.
        </div>
        <div class="col-sm-6">
          <div class="text-sm-right d-none d-sm-block">
            Developed by The Boardwalk Realty Dev Team.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>