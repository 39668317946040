<template>
  <div class="dropdown w-100">
    <button class="form-control dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
      <div>{{ selectedState.name ? selectedState.name : 'Select State' }}</div>
      <i class="fas fa-chevron-down"></i>
    </button>
    <div class="dropdown-menu w-100 custom-shadow" aria-labelledby="dropdownMenuButton1">
      <div class="w-100 p-2">
        <input type="text" class="form-control" placeholder="Search state..." @keyup="filterStates($event)">
      </div>
      <div class="dropdown-item-list">
        <div class="dropdown-item" v-for="(state, index) in filteredStates" :key="index" @click="selectState(index)">
          <i class="fas fa-map-marked-alt"></i>
          <span>{{ state.name }} </span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { ref, onMounted } from  'vue'

  export default {
    props: ['state', 'element'],
    emits: ['selectState'],
    setup(props, {emit}) {
      const store = useStore()
      const router = useRouter()

      var states = ref(store.getters.states)
      var filteredStates = ref(store.getters.states)
      var selectedState = ref(props.state)
      var element = ref(props.element)

      onMounted(() => {
      })

      const filterStates = (e) => {
        let filtered = states.value.filter((state) => {
          if(state.name.toUpperCase().includes(e.target.value.toUpperCase())){
            return state;
          }
        })
        filteredStates.value = filtered
      }

      const selectState = (index) => {
        emit('selectState', filteredStates.value[index], element.value)
        selectedState.value = filteredStates.value[index]
      }

      return{
        filteredStates,
        selectedState,
        selectState,
        filterStates,
      }
    },
  }
</script>

<style lang="scss">
.dropdown-toggle.form-control{
  min-width: 220px;
}
  .dropdown-item-list{
    max-height: 200px;
    overflow-y: auto;
    .dropdown-item{
      display: flex;
      align-items: center;
      white-space: initial;
      line-height: initial;
      cursor: pointer;
      padding: 0.5rem 1rem;
      transition: .3s;
      &:hover{
        background-color: aliceblue;
      }
      
      i{
        margin-right: 15px;
      }
    }
  }
</style>