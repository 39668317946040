<template>
  <div class="dropdown w-100">
    <button
      class="btn btn-info dropdown-toggle"
      :class="{ show: showDropdown }"
      type="button"
      id="dropdownProperty"
      aria-expanded="false"
      data-bs-auto-close="false"
      style="float: right"
      data-bs-reference="parent"
      @click="toggleDropdown()"
    >
      <i class="fas fa-plus me-2"></i>
      <div>New</div>
    </button>
    <div
      class="dropdown-menu w-500 custom-shadow dropdown-menu-end p-3 mt-5"
      :class="{ show: showDropdown }"
      aria-labelledby="dropdownProperty"
    >
      <div class="row mt-4">
        <div class="col-8">
          <div class="form-group">
            <label for="">Property Name</label>
            <input type="text" class="form-control" v-model="v.name.$model" :class="{ 'is-invalid': v.name.$error }" />
            <p class="invalid-feedback d-block m-0">{{ v.name.$errors[0]?.$message }}</p>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for=""># MLS</label>
            <input type="text" class="form-control" v-model="propertyForm.mls" />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Description</label>
            <input type="text" class="form-control" v-model="propertyForm.description" />
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label for="">State</label>
            <dropdown-states :state="propertyForm.state" @selectState="selectState"></dropdown-states>
            <p class="invalid-feedback d-block m-0">{{ v.state.$errors[0]?.$message }}</p>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="">City</label>
            <input type="text" class="form-control" v-model="propertyForm.city" />
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label for="">Address</label>
            <input type="text" class="form-control" v-model="propertyForm.address" />
          </div>
        </div>
        <div class="col-12 text-center mt-2">
          <button class="btn btn-secondary me-2" @click="showDropdown = false">Cancel</button>
          <button class="btn btn-primary" @click="saveProperty()">Create Property</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, onMounted, reactive } from 'vue'

import PropertyService from '../../services/property.service'
import DropdownStates from './DropdownStates.vue'

import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  components: {
    DropdownStates,
  },
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()

    var showDropdown = ref(false)

    var propertyForm = reactive({
      name: '',
      description: '',
      address: '',
      mls: '',
      state: {
        name: 'Select State',
        id: '',
      },
      state_id: '',
      city: '',
      status_id: 1,
    })

    onMounted(() => {})

    const closeDropdown = () => {
      showDropdown.value = false
    }

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value
    }

    const selectState = (state) => {
      propertyForm.state = state
      propertyForm.state_id = state.id
    }

    //validation
    const rules = {
      name: { required },
      state: { required: helpers.withMessage('State is required', (value) => value.id !== '') },
    }

    const v = useVuelidate(rules, propertyForm, { $scope: false })

    const reset = () => {
      propertyForm.name = ''
      propertyForm.description = ''
      propertyForm.address = ''
      propertyForm.mls = ''
      propertyForm.state = {}
      propertyForm.state_id = ''
      propertyForm.city = ''
      propertyForm.status_id = 1
      v.value.$reset()
    }

    const saveProperty = async () => {
      const isValid = await v.value.$validate()
      if (!isValid) return

      const response = await PropertyService.createProperty(propertyForm)
      reset()
      closeDropdown()
      emit('getNewProperty', response)
    }

    return {
      showDropdown,
      propertyForm,
      selectState,
      saveProperty,
      toggleDropdown,
      v,
    }
  },
}
</script>
