import axios from '@/config/axios'

class NotaryService {
  async fetchAll() {
    try {
      const resp = await axios.get('/notary')
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchNotary(id) {
    try {
      const resp = await axios.get(`/notary/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async createNotary({ name }) {
    try {
      const resp = await axios.post('/notary', { name })
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async updateNotary(id, { name }) {
    try {
      const resp = await axios.put(`/notary/${id}`, { name })
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async deleteNotary(id) {
    try {
      const resp = await axios.delete(`/notary/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }
}

export default new NotaryService()
