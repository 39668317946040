<template>
      <VueSidebarMenuAkahon />
</template>

<script>
  import VueSidebarMenuAkahon from "vue-sidebar-menu-akahon";
  
  export default {
    name: 'component.vue',
    components: {VueSidebarMenuAkahon},
    data() {
      return {}
    }
  }
</script>