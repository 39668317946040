<template>
  <div class="dropdown w-100">
    <button class="form-control dropdown-toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <div>{{ selectedAttorney.name ? selectedAttorney.name : 'Select an Attorney'}}</div>
      <i class="fas fa-chevron-down"></i>
    </button>
    <div class="dropdown-menu w-100 custom-shadow" aria-labelledby="dropdownMenuButton1">
      <div class="w-100 p-2">
        <input type="text" class="form-control" placeholder="Search attorney..." @keyup="filterAttorneys($event)">
      </div>
      <div class="dropdown-item-list">
        <div class="dropdown-item" v-for="(attorney, index) in filteredAttorneys" :key="index" @click="selectAttorney(index)">
          <i class="fas fa-balance-scale-left"></i>
          <span>{{ attorney.name }} </span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import { ref, onMounted } from  'vue'

  import AttorneyService from '../../services/attorney.service'
  export default {
    props: ['attorney'],
    emits: ['selectAttorney'],
    setup(props, {emit}) {

      var attorneys = ref([])
      var filteredAttorneys = ref([])
      var selectedAttorney = ref(props.attorney)

      onMounted(() => {
        getAttorneys()
      })

      const getAttorneys = async () => {
        attorneys.value = await AttorneyService.fetchAll()
        filteredAttorneys.value = attorneys.value
      }

      const filterAttorneys = (e) => {
        let filtered = attorneys.value.filter((attorney) => {
          if(attorney.name.toUpperCase().includes(e.target.value.toUpperCase())){
            return attorney;
          }
        })
        filteredAttorneys.value = filtered
      }

      const selectAttorney = (index) => {
        emit('selectAttorney', filteredAttorneys.value[index])
        selectedAttorney.value = filteredAttorneys.value[index]
      }

      return{
        filteredAttorneys,
        selectedAttorney,
        selectAttorney,
        filterAttorneys,
      }
    },
  }
</script>

<style lang="scss">
.dropdown-toggle.form-control{
  min-width: 220px;
}
  .dropdown-item-list{
    max-height: 200px;
    overflow-y: auto;
    .dropdown-item{
      display: flex;
      align-items: center;
      white-space: initial;
      line-height: initial;
      cursor: pointer;
      padding: 0.5rem 1rem;
      transition: .3s;
      &:hover{
        background-color: aliceblue;
      }
      
      i{
        margin-right: 15px;
      }
    }
  }
</style>