import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'

export function useDatatable() {
  const loading = ref(false)
  const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })
  const dt = ref()
  const numRows = ref(10)
  const editingRows = ref([])
  const empty = ref(false)

  const exportCSV = () => {
    dt.value.exportCSV()
  }

  return { loading, filters, numRows, dt, exportCSV, editingRows, empty }
}
