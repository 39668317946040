import { createRouter, createWebHistory } from 'vue-router'

import Modal from '../views/Modal.vue'
import Login from '../views/Login.vue'

import Home from '../views/Home.vue'
import Deals from '../views/deals/Deals.vue'
import Properties from '../views/Properties.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/Modal',
        component: Modal,
      },
      {
        path: '/deals',
        component: Deals,
      },
      {
        path: '/deal-settings',
        component: () => import('../views/deal_settings/DealSettings.vue'),
      },
      {
        path: '/clients',
        component: () => import('../views/clients/Clients.vue'),
      },
      {
        path: '/properties',
        component: () => import('../views/properties/Properties.vue'),
      },
      {
        path: '/users',
        component: () => import('../views/users/Users.vue'),
      },
      {
        path: '/agent-levels',
        component: () => import('../views/levels/AgentLevels.vue'),
      },
      {
        path: '/office-managment',
        component: () => import('../views/office_manager/OfficeManager.vue'),
      },
      {
        path: '/office-managment/edit-deal/:id',
        component: () => import('../views/office_manager/DealsEdit.vue'),
      },
      {
        path: '/admin/configuration',
        component: () => import('../views/configuration/Configuration.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
