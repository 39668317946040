import axios from '@/config/axios'

class AttorneyService {
  async fetchAll() {
    try {
      const resp = await axios.get('/attorney')
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchAttorney(id) {
    try {
      const resp = await axios.get(`/attorney/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async createAttorney({ name }) {
    try {
      const resp = await axios.post('/attorney', { name })
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async updateAttorney(id, { name }) {
    try {
      const resp = await axios.put(`/attorney/${id}`, { name })
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async deleteAttorney(id) {
    try {
      const resp = await axios.delete(`/attorney/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }
}

export default new AttorneyService()
