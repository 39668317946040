<template>
  <div class="sidebar" id="sidebar">
    <nav class="navbar-nav p-2" v-if="!loading">
      <div class="d-flex align-items-center justify-content-center w-100 p-2 white">
        <img class="icon-header" src="img/logo_boardwalk.png" alt="">
        <h3 class="mb-0 text-start">Boardwalk</h3>
      </div>
      <hr class="w-100 white">
      <div class="nav-item">
        <small><strong>DASHBOARD MENU </strong></small>
      </div>
      <div class="nav-item">
        <div class="menu-item mb-2">
          <i class="fas fa-user-shield"></i>
          <label for="">System Role</label>
        </div>
        <div class="dropdown w-100">
          <button class="btn btn-dark dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <div class="role-name">{{selectedRole ? selectedRole.name : ''}}</div>
            <i class="fas fa-chevron-down"></i>
          </button>
          <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
            <a class="dropdown-item" v-for="(role, index) in roles" :key="index" @click="selectRole(index)">{{ role ? role.name : '' }}</a>
          </div>
        </div>
      </div>
      <hr class="w-100 white">
      <div class="nav-item">
        <div class="menu-item mb-2">
          <i class="fas fa-umbrella"></i>
          <label for="">Team</label>
        </div>
        
        <!-- <div v-if="selectedRole.id === 1 || selectedRole.id === 2 || selectedRole.id === 3" class="dropdown w-100">
          <button class="btn btn-dark dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <div>{{ selectedTeam !== null ? selectedTeam.name : 'Select a team'  }}</div>
            <i class="fas fa-chevron-down"></i>
          </button>
          <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
            <a class="dropdown-item" v-for="(team, index) in teams" :key="index" @click="selectTeam(index)">{{ team.name }}</a>
          </div>
        </div> -->
        <span class="white fw-600">{{ userTeam !== null ? userTeam.name : 'No Team Assigned' }}</span>
      </div>
      <div class="nav-item">
        <div class="menu-item mb-2">
          <i class="fas fa-users"></i>
          <label for="">Team Role</label>
        </div>
        <div v-if="userTeamRoles.length > 1"
          class="dropdown w-100">
          <button class="btn btn-dark dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <div>{{ selectedTeamRole !== null ? selectedTeamRole.name : 'Select a Role'  }}</div>
            <i class="fas fa-chevron-down"></i>
          </button>
          <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
            <a class="dropdown-item" v-for="(team, index) in userTeamRoles" :key="index" @click="selectTeamRole(index)">{{ team.name }}</a>
          </div>
        </div>
        <div v-else-if="userTeamRoles.length == 1">
          <span class="white fw-600">
            {{ selectedTeamRole.name}}
          </span>
        </div>
        <div v-else>
          <span class="white fw-600">
            No Role Assigned
          </span>
        </div>
      </div>
      <hr class="w-100 white">
      <div class="menu-list">
        <div v-if="selectedRole.id === 1 || selectedRole.id === 3 || selectedRole.id === 4" 
          class="nav-item">
          <div class="menu-item" @click="go('/deals')">
            <i class="fas fa-handshake"></i>
            <label for="">Deals</label>
          </div>
        </div>
        <div v-if="selectedRole.id === 1 || selectedRole.id === 2 || selectedRole.id === 3"
          class="nav-item">
          <div class="menu-item" @click="go('/deal-settings')">
            <i class="fas fa-cogs"></i>
            <label for="">Deal Settings</label>
          </div>
        </div>
        <div class="nav-item">
          <div class="menu-item" @click="go('/clients')">
            <i class="fas fa-user-tie"></i>
            <label for="">Clients</label>
          </div>
        </div>
        <div class="nav-item">
          <div class="menu-item" @click="go('/properties')">
            <i class="fas fa-building"></i>
            <label for="">Properties</label>
          </div>
        </div>
        <div class="nav-item">
          <div class="menu-item">
            <i class="fas fa-book"></i>
            <label for="">History</label>
          </div>
        </div>
        <div v-if="selectedRole.id === 1 || selectedRole.id === 3"
          class="nav-item">
          <div class="menu-item" @click="go('/users')">
            <i class="fas fa-users-cog"></i>
            <label for="">Users</label>
          </div>
        </div>
        <div v-if="selectedRole.id === 1 || selectedRole.id === 2 || selectedRole.id === 3"
          class="nav-item">
          <div class="menu-item" @click="go('/agent-levels')">
            <i class="fas fa-hand-holding-usd"></i>
            <label for="">Agent Level</label>
          </div>
        </div>        
        <div v-if="selectedRole.id === 1 || selectedRole.id === 2 || selectedRole.id === 3"
          class="nav-item">
          <div class="menu-item" @click="go('/office-managment')">
            <i class="fas fa-file-invoice-dollar"></i>
            <label for="">Office Management</label>
          </div>
        </div>
        <hr class="w-100 white">
        <div v-if="selectedRole.id === 1"
          class="nav-item">
          <div class="menu-item" @click="go('/admin/configuration')">
            <i class="fas fa-cog"></i>
            <label for="">Configuration</label>
          </div>
        </div>
      </div>
      
    </nav>
  </div>
</template>

<script>
import TeamService from '../services/team.service'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, onMounted, computed, watch } from 'vue'
export default {
  setup() {
    const router = useRouter();
    const store = useStore()
    const teams = computed(() => store.getters.teams);
    const roles = computed(() => store.getters.userRoles)
    const selectedTeam = computed(() => store.state.selectedTeam);
    const userTeam = computed(() => store.state.user.user.team);
    const userTeamRoles = computed(() => store.state.user.user.team_roles);
    const selectedRole = computed(() => store.state.selectedRole);
    const selectedTeamRole = computed(() => store.state.selectedTeamRole);
    const loading = ref(true)
    const state = ref(store.state)
    
    onMounted(() => {
      if(state.value.selectedRole == null){
        selectRole(0)
      }else if(Object.keys(state.value.selectedRole).length === 0){
        selectRole(0)
      }

      if(state.value.selectedTeamRole == null){
        if(userTeamRoles.value.length >= 1){
          console.log("Entra Aquí")
          selectTeamRole(0)
        }
      }else if(Object.keys(state.value.selectedTeamRole).length === 0){
        if(userTeamRoles.value.length >= 1){
          console.log("MAs bien aqui")
          selectTeamRole(0)
        }
      }
      loading.value = false
    })

    const selectTeam = (index) => {
      store.dispatch('selectTeam', teams.value[index])
    }

    const selectRole = (index) => {
      store.dispatch('selectRole', roles.value[index])
    }

    const selectTeamRole = (index) => {
      store.dispatch('selectTeamRole', userTeamRoles.value[index])
    }

    const go = (link) => {
      router.push(link)
    }

    return {
      roles,
      userTeamRoles,
      userTeam,
      selectedRole,
      selectedTeamRole,
      teams,
      selectedTeam,
      selectTeam,
      selectRole,
      selectTeamRole,
      go, 
      loading,
    }
  },
}
</script>

<style lang="scss">
.role-name{
  text-transform: capitalize;
}
</style>