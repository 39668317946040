<template>
  <Dialog
    v-model:visible="showModal"
    :modal="true"
    icon="pi pi-external-link"
    position="center"
    :style="{ width: '70vw' }"
    @hide="$emit('close')"
    header="Edit Deal"
  >
    <form class="row" @submit.prevent="() => {}">
      <div class="modal-body text-start">
        <div class="row mb-3">
          <div v-if="selectedRole.name === 'Admin'" class="row">
            <div class="col-3"></div>
            <div class="col-6">
              <i class="fas fa-user-tie fa-lg me-2"></i>
              <label> Agent: </label>
              <DropdownAgents :agent="selectedAgent" @selectAgent="selectAgent"></DropdownAgents>
              <p class="invalid-feedback d-block m-0">{{ deal_v$.agent_id.$errors[0]?.$message }}</p>
              <br />
            </div>
          </div>
          <div class="col-6 pe-4">
            <div class="row">
              <Fieldset :toggleable="true" :collapsed="false">
                <template #legend>
                  <div class="d-flex align-items-center">
                    <i class="far fa-building me-2 fa-xs"></i>
                    <h5 class="mb-0">Property information</h5>
                  </div>
                </template>
                <div class="d-flex">
                  <div>
                    <div
                      class="btn btn-secondary me-2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsePropertyInfo"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <i class="fas fa-chevron-down"></i>
                    </div>
                  </div>
                  <div class="me-2">
                    <DropdownNewProperty @getNewProperty="getNewProperty"></DropdownNewProperty>
                  </div>
                  <div class="w-100">
                    <DropdownProperties
                      :property="selectedProperty"
                      @selectProperty="selectProperty"
                      :refresh="refreshProperties"
                    ></DropdownProperties>
                    <p class="invalid-feedback d-block m-0 ms-2">{{ deal_v$.property_id.$errors[0]?.$message }}</p>
                  </div>
                </div>
                <div class="collapse" id="collapsePropertyInfo">
                  <div class="d-flex flex-column py-2 card-gradient mb-3 px-3">
                    <div class="item-list">
                      <small>Property Name</small>
                      <b>{{ selectedProperty.name }}</b>
                    </div>
                    <div class="item-list">
                      <small>Description</small>
                      <b>{{ selectedProperty.description }}</b>
                    </div>
                    <div class="item-list">
                      <small># MLS</small>
                      <b>{{ selectedProperty.mls }}</b>
                    </div>
                    <div class="item-list">
                      <small>Address</small>
                      <b>{{ selectedProperty.address }}</b>
                    </div>
                  </div>
                </div>
              </Fieldset>
            </div>
          </div>
          <div class="col-6 ps-4">
            <div class="row">
              <Fieldset :toggleable="true" :collapsed="false">
                <template #legend>
                  <div class="d-flex align-items-center">
                    <i class="far fa-user me-2 fa-xs"></i>
                    <h5 class="mb-0">Client information</h5>
                  </div>
                </template>
                <div class="d-flex">
                  <div
                    class="btn btn-secondary me-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseClientInfo"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i class="fas fa-chevron-down"></i>
                  </div>
                  <div class="me-2">
                    <DropdownNewClient @getNewClient="getNewClient" @error="error"></DropdownNewClient>
                  </div>
                  <div class="w-100">
                    <DropdownClients
                      :client="selectedClient"
                      @selectClient="selectClient"
                      :refresh="refreshClients"
                    ></DropdownClients>
                    <p class="invalid-feedback d-block m-0 ms-2">{{ deal_v$.client_id.$errors[0]?.$message }}</p>
                  </div>
                </div>
                <div class="collapse" id="collapseClientInfo">
                  <div class="d-flex flex-column py-2 text-end card-gradient mb-3 px-3">
                    <div class="item-list">
                      <small>Client Name</small>
                      <b>{{ selectedClient.name }} {{ selectedClient.last_name }}</b>
                    </div>
                    <div class="item-list">
                      <small>Email</small>
                      <b>{{ selectedClient.email }}</b>
                    </div>
                    <div class="item-list">
                      <small>Phone</small>
                      <b>{{ selectedClient.phone }}</b>
                    </div>
                    <div class="item-list">
                      <small>Address</small>
                      <b>{{ selectedClient.address }}</b>
                      <br />
                      <b
                        >{{ selectedClient.city ? selectedClient.city + ',' : '' }}
                        {{ selectedClient.state ? selectedClient.state + ',' : '' }} {{ selectedClient.country }}</b
                      >
                    </div>
                  </div>
                </div>
              </Fieldset>
            </div>
          </div>
        </div>

        <div class="row">
          <Fieldset :toggleable="true">
            <template #legend>
              <i class="far fa-handshake me-2 fa-xs"></i>
              <h5 class="mb-0">Deal information</h5>
            </template>
            <div class="row">
              <div class="col-3">
                <div class="form-group">
                  <label for="">Sale Type</label>
                  <select
                    name=""
                    id=""
                    class="form-select"
                    v-model="deal_v$.sale_type_id.$model"
                    :class="{ 'is-invalid': deal_v$.sale_type_id.$error }"
                  >
                    <option value="1">Standard Deal</option>
                    <option value="2">Pre-Construction</option>
                  </select>
                  <p class="invalid-feedback d-block m-0">{{ deal_v$.sale_type_id.$errors[0]?.$message }}</p>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="">Deal Status</label>
                  <select
                    name=""
                    id=""
                    class="form-select t-upper"
                    v-model="deal_v$.deal_status_id.$model"
                    :class="{ 'is-invalid': deal_v$.deal_status_id.$error }"
                  >
                    <option v-for="(status, index) in dealStatus" :key="index" :value="status.id">
                      {{ status.name }}
                    </option>
                  </select>
                  <p class="invalid-feedback d-block m-0">{{ deal_v$.deal_status_id.$errors[0]?.$message }}</p>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="">Representing</label>
                  <select
                    name=""
                    id=""
                    class="form-select"
                    v-model="deal_v$.representing.$model"
                    :class="{ 'is-invalid': deal_v$.representing.$error }"
                  >
                    <option value="buyer">Buyer</option>
                    <option value="seller">Seller</option>
                    <option value="both">Both</option>
                  </select>
                  <p class="invalid-feedback d-block m-0">{{ deal_v$.representing.$errors[0]?.$message }}</p>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="">Source</label>
                  <select
                    name=""
                    id=""
                    class="form-select"
                    v-model="deal_v$.deal_source_id.$model"
                    :class="{ 'is-invalid': deal_v$.deal_source_id.$error }"
                  >
                    <option v-for="(source, index) in sources" :key="index" :value="source.id">
                      {{ source.name }}
                    </option>
                  </select>
                  <p class="invalid-feedback d-block m-0">{{ deal_v$.deal_source_id.$errors[0]?.$message }}</p>
                </div>
              </div>

              <div class="col-3">
                <div class="form-group">
                  <label for="">Sales Price</label>
                  <InputNumber
                    mode="decimal"
                    :minFractionDigits="2"
                    :prefix="'$ '"
                    :class="{ 'p-invalid': deal_v$.sale_price.$error }"
                    inputClass="w-100"
                    v-model="deal_v$.sale_price.$model"
                    @keyup="salePriceKeyup($event)"
                  />
                  <p class="invalid-feedback d-block m-0">{{ deal_v$.sale_price.$errors[0]?.$message }}</p>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="">% to BR</label>
                  <InputNumber
                    mode="decimal"
                    :minFractionDigits="2"
                    :prefix="'% '"
                    :class="{ 'p-invalid': deal_v$.br_percentage.$error }"
                    inputClass="w-100"
                    v-model="deal_v$.br_percentage.$model"
                    @keyup="calculateBrMoney($event)"
                  />
                  <p class="invalid-feedback d-block m-0">{{ deal_v$.br_percentage.$errors[0]?.$message }}</p>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="">Money to BR</label>
                  <InputNumber
                    mode="decimal"
                    :minFractionDigits="2"
                    :prefix="'$ '"
                    :class="{ 'p-invalid': deal_v$.br_money.$error }"
                    inputClass="w-100"
                    v-model="deal_v$.br_money.$model"
                    @keyup="calculateBrPercentage($event)"
                  />
                  <p class="invalid-feedback d-block m-0">{{ deal_v$.br_money.$errors[0]?.$message }}</p>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="">Cooperation</label>
                  <input type="text" class="form-control" v-model="deal.cooperation" />
                </div>
              </div>
              <div class="col-12">
                <hr />
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6 d-flex flex-column">
                    <h6 for="" class="ws-nowrap fw-600 mb-0">Do you have referrals?</h6>
                    <small>Add them</small>
                  </div>
                  <div class="col-6 ps-1">
                    <div class="dropdown" data-bs-offset="10,20">
                      <button
                        class="btn btn-warning btn-sm dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style="float: right"
                      >
                        <i class="fas fa-plus me-2"></i>
                        <div>Add</div>
                      </button>
                      <div
                        class="dropdown-menu w-400 dropdown-menu-end custom-shadow"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label for="">Name</label>
                              <input type="text" class="form-control" v-model="referralForm.name" />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label for="">FEE type</label>
                              <select name="" id="" class="form-select" v-model="referralForm.type">
                                <option value="percentage">% Percentage</option>
                                <option value="money">$ Money</option>
                              </select>
                            </div>
                          </div>
                          <div v-if="referralForm.type === 'money'" class="col-6">
                            <div class="form-group">
                              <label for="">FEE Money</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="referralForm.fee_money"
                                @keyup="calculatePercentage($event, 'referral')"
                              />
                            </div>
                          </div>
                          <div v-else class="col-6">
                            <div class="form-group">
                              <label for="">FEE Percentage</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="referralForm.fee_percentage"
                                @keyup="calculateMoney($event, 'referral')"
                              />
                            </div>
                          </div>
                          <div class="col-3"></div>
                          <div class="col-6 text-center">
                            <button class="btn btn-primary" @click="addReferral()">Add Referral</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-6" v-for="(referral, index) in deal.referrals" :key="index">
                    <div class="referral-item" :class="{ 'me-3': index % 2 === 0, 'ms-3': index % 2 !== 0 }">
                      <div>
                        {{ referral.name }} <br />
                        <small>{{ referral.fee_percentage }} % </small> &nbsp; &nbsp; | &nbsp; &nbsp;
                        <small>$ {{ referral.fee_money }} </small>
                      </div>
                      <button class="btn btn-danger btn-sm" @click="removeReferral(index)">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6 d-flex flex-column">
                    <h6 for="" class="ws-nowrap fw-600 mb-0">Do you have assistants?</h6>
                    <small>Add them</small>
                  </div>
                  <div class="col-6 ps-1">
                    <div class="dropdown" data-bs-offset="10,20">
                      <button
                        class="btn btn-warning btn-sm dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style="float: right"
                      >
                        <i class="fas fa-plus me-2"></i>
                        <div>Add</div>
                      </button>
                      <div
                        class="dropdown-menu w-400 dropdown-menu-end custom-shadow"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label for="">Name</label>
                              <input type="text" class="form-control" v-model="assistantForm.name" />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label for="">FEE type</label>
                              <select name="" id="" class="form-select" v-model="assistantForm.type">
                                <option value="percentage">% Percentage</option>
                                <option value="money">$ Money</option>
                              </select>
                            </div>
                          </div>
                          <div v-if="assistantForm.type === 'money'" class="col-6">
                            <div class="form-group">
                              <label for="">FEE Money</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="assistantForm.fee_money"
                                @keyup="calculatePercentage($event, 'assistant')"
                              />
                            </div>
                          </div>
                          <div v-else class="col-6">
                            <div class="form-group">
                              <label for="">FEE Percentage</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="assistantForm.fee_percentage"
                                @keyup="calculateMoney($event, 'assistant')"
                              />
                            </div>
                          </div>
                          <div class="col-3"></div>
                          <div class="col-6 text-center">
                            <button class="btn btn-primary" @click="addAssistant()">Add Assistant</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-6" v-for="(assistant, index) in deal.assistants" :key="index">
                    <div class="assistant-item" :class="{ 'me-3': index % 2 === 0, 'ms-3': index % 2 !== 0 }">
                      <div>
                        {{ assistant.name }} <br />
                        <small>{{ assistant.fee_percentage }} %</small> &nbsp; &nbsp; | &nbsp; &nbsp;
                        <small>$ {{ assistant.fee_money }}</small>
                      </div>
                      <button class="btn btn-danger btn-sm" @click="removeAssistant(index)">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fieldset>
          <div class="col-12">
            <hr />
          </div>
        </div>

        <div class="row">
          <Fieldset :toggleable="true" :collapsed="true">
            <template #legend>
              <div class="d-flex align-items-center">
                <i class="fas fa-flag-checkered me-2 fa-xs"></i>
                <h5 class="mb-0">Closing information</h5>
              </div>
            </template>
            <div class="row">
              <div class="col-3">
                <div class="form-group">
                  <label for="">AEO</label>
                  <input type="date" class="form-control" v-model="deal.aeo" />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="">COE</label>
                  <input type="date" class="form-control" v-model="deal.coe" />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="">EXT</label>
                  <input type="date" class="form-control" v-model="deal.ext" />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="">Escrow Number</label>
                  <input type="text" class="form-control" v-model="deal.escrow_number" />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label for="">Escrow Agency</label>
                  <DropdownEscrowAgencies
                    :agency="selectedAgency"
                    @selectAgency="selectAgency"
                  ></DropdownEscrowAgencies>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label for="">Notary</label>
                  <DropdownNotaries :notary="selectedNotary" @selectNotary="selectNotary"></DropdownNotaries>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label for="">Attorney</label>
                  <DropdownAttorneys :attorney="selectedAttorney" @selectAttorney="selectAttorney"></DropdownAttorneys>
                </div>
              </div>
              <div class="col-12">
                <label for="">Special Conditions</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="2"
                  class="form-control"
                  v-model="deal.special_conditions"
                ></textarea>
              </div>
            </div>
          </Fieldset>
        </div>
      </div>
    </form>

    <template #footer>
      <button type="button" class="btn btn-secondary" @click="$emit('close')">Close</button>
      <button type="submit" class="btn btn-primary" @click="updateDeal()">Update</button>
    </template>
  </Dialog>
</template>

<script>
import { computed, reactive, ref, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'

import { required, helpers, requiredUnless } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

import DropdownAttorneys from '../../../components/dropdowns/DropdownAttorneys.vue'
import DropdownAgents from '../../../components/dropdowns/DropdownAgents.vue'
import DropdownNotaries from '../../../components/dropdowns/DropdownNotaries.vue'
import DropdownNewClient from '../../../components/dropdowns/DropdownNewClient.vue'
import DropdownNewProperty from '../../../components/dropdowns/DropdownNewProperty.vue'
import DropdownEscrowAgencies from '../../../components/dropdowns/DropdownEscrowAgencies.vue'
import DropdownClients from '../../../components/dropdowns/DropdownClients.vue'
import DropdownProperties from '../../../components/dropdowns/DropdownProperties.vue'

import Dialog from 'primevue/dialog'
import Fieldset from 'primevue/fieldset'
import Divider from 'primevue/divider'
import { useToast } from 'primevue/usetoast'
import InputNumber from 'primevue/inputnumber'

import DealService from '../../../services/deal.service'
import Deal from '../../../models/Deal'

export default {
  components: {
    Dialog,
    Divider,
    Fieldset,
    InputNumber,
    DropdownAttorneys,
    DropdownAgents,
    DropdownNotaries,
    DropdownNewClient,
    DropdownNewClient,
    DropdownNewProperty,
    DropdownEscrowAgencies,
    DropdownClients,
    DropdownProperties,
  },
  props: {
    showModal: { type: Boolean, default: false },
    deal: { type: Object },
  },
  emits: ['close', 'onUpdate'],
  setup(props, { emit }) {
    const store = useStore()
    const deal = reactive(new Deal())
    const toast = useToast()
    const user = computed(() => store.getters.user)
    var selectedRole = computed(() => store.state.selectedRole)

    var refreshClients = ref(false)
    var selectedClient = ref({})

    var refreshProperties = ref(false)
    var selectedProperty = ref({})

    var dealStatus = computed(() => store.getters.dealStatus)
    var sources = computed(() => store.getters.sources)

    var referralForm = ref({
      name: '',
      type: 'percentage',
      fee_money: '',
      fee_percentage: '',
    })

    var assistantForm = ref({
      name: '',
      type: 'percentage',
      fee_money: '',
      fee_percentage: '',
    })

    var selectedNotary = ref({})
    var selectedAttorney = ref({})
    var selectedAgency = ref({})
    var selectedAgent = ref({})

    //Validation
    const dealRules = computed(() => ({
      sale_type_id: { required },
      deal_status_id: { required },
      representing: { required },
      deal_source_id: { required },
      sale_price: { required },
      br_percentage: { required },
      br_money: { required },
      property_id: { required: helpers.withMessage('Property is required', (value) => value !== undefined) },
      agent_id: { required: helpers.withMessage('Agent is required', (value) => value !== undefined) },
      client_id: { required: helpers.withMessage('Client is required', (value) => value !== undefined) },
    }))

    const referralRules = computed(() => ({
      name: { required },
      fee_money: { requiredUnless: requiredUnless(referralForm.value.fee_percentage) },
      fee_percentage: { requiredUnless: requiredUnless(referralForm.value.fee_money) },
    }))

    const assistantRules = computed(() => ({
      name: { required },
      fee_money: { requiredUnless: requiredUnless(assistantForm.value.fee_percentage) },
      fee_percentage: { requiredUnless: requiredUnless(assistantForm.value.fee_money) },
    }))

    const deal_v$ = useVuelidate(dealRules, deal)
    const referral_v$ = useVuelidate(referralRules, referralForm, { $scope: false })
    const assistant_v$ = useVuelidate(assistantRules, assistantForm, { $scope: false })

    watch(
      () => props.showModal,
      () => {
        if (props.showModal) {
          resetForm()
          if (selectedRole.value.name === 'General') {
            deal.agent_id = user.value.id
          } else {
            selectedAgent.value = {}
            deal.agent_id = undefined
          }

          const editDeal = props.deal
          selectAgent(editDeal.agent)
          selectProperty(editDeal.property)
          selectClient(editDeal.client)
          deal.sale_type_id = editDeal.sale_type_id
          deal.deal_status_id = editDeal.deal_status_id
          deal.representing = editDeal.representing
          deal.deal_source_id = editDeal.deal_source_id
          deal.sale_price = Number(editDeal.sale_price)
          deal.br_percentage = Number(editDeal.br_percentage)
          deal.br_money = Number(editDeal.br_money)
          deal.cooperation = editDeal.cooperation
          editDeal.referrals.forEach((r) => {
            deal.referrals.push(r)
          })
          editDeal.assistants.forEach((a) => {
            deal.assistants.push(a)
          })
          deal.aeo = editDeal.aeo
          deal.coe = editDeal.coe
          deal.ext = editDeal.ext
          deal.escrow_number = editDeal.escrow_number
          selectAgency(editDeal.escrow_agency)
          selectNotary(editDeal.notary)
          selectAttorney(editDeal.attorney)
          deal.special_conditions = editDeal.special_conditions
          calculateDealValues()
        }
      }
    )

    const selectAgent = (data) => {
      selectedAgent.value = data
      deal.agent_id = selectedAgent.value.id
      deal.team_id = selectedAgent.value.team_id
    }

    const getNewProperty = (property) => {
      toast.add({ severity: 'success', summary: 'Property created!', detail: '', life: 3000 })
      selectedProperty.value = property
      deal.property_id = selectedProperty.value.id
      refreshProperties.value = true
    }

    const getNewClient = (client) => {
      toast.add({ severity: 'success', summary: 'Client created!', detail: '', life: 3000 })
      selectedClient.value = client
      deal.client_id = selectedClient.value.id
      refreshClients.value = true
    }

    const selectProperty = (property) => {
      selectedProperty.value = property
      deal.property_id = selectedProperty.value.id
    }

    const selectClient = (client) => {
      selectedClient.value = client
      deal.client_id = selectedClient.value.id
    }

    const selectNotary = (notary) => {
      if (typeof notary !== 'undefined' && notary !== null && notary !== '') {
        selectedNotary.value = notary
        deal.notary_id = selectedNotary.value.id
      } else {
        selectedNotary.value = {}
        deal.notary_id = undefined
      }
    }

    const selectAgency = (agency) => {
      if (typeof agency !== 'undefined' && agency !== null && agency !== '') {
        selectedAgency.value = agency
        deal.escrow_agency_id = selectedAgency.value.id
      } else {
        selectedAgency.value = {}
        deal.escrow_agency_id = undefined
      }
    }

    const selectAttorney = (attorney) => {
      if (typeof attorney !== 'undefined' && attorney !== null && attorney !== '') {
        selectedAttorney.value = attorney
        deal.attorney_id = selectedAttorney.value.id
      } else {
        selectedAttorney.value = {}
        deal.attorney_id = undefined
      }
    }

    const salePriceKeyup = (e) => {
      let cleanSalePrice = e.target.value.replace('$ ', '')
      cleanSalePrice = cleanSalePrice.replace(/,/g, '')
      calculateDealValues(parseFloat(cleanSalePrice))
      recalculateReferrals()
    }

    const recalculateReferrals = (br_money = deal.br_money) => {
      for (let i = 0; i < deal.referrals.length; i++) {
        deal.referrals[i].fee_money = ((deal.referrals[i].fee_percentage * parseFloat(br_money)) / 100).toFixed(2)
      }
    }

    const calculateDealValues = (salePrice = deal.sale_price) => {
      const values = {
        salePrice: salePrice,
        brPercentage: deal.br_percentage,
        referrals: deal.referrals,
        assistants: deal.assistants,
        teamPercentage: 0,
        closingCordPercentage: 0,
        agentSplitPercentage: 0,
      }
      const data = DealService.calculateDealValues(values)

      deal.br_money = data.br_money
      deal.ref_fee_total = data.ref_fee_total
      deal.left_to_split_1 = data.left_to_split_1
      deal.team_money = data.team_money
      deal.left_to_br = data.left_to_br
      deal.closing_cord_money = data.closing_cord_money
      deal.left_to_split_2 = data.left_to_split_2
      deal.agent_split_money = data.agent_split_money
      deal.assistants_fee_total = data.assistants_fee_total
      deal.left_to_split_3 = data.left_to_split_3
      deal.head = data.head
    }

    const calculateBrMoney = (e) => {
      let cleanValue = e.target.value.replace('% ', '')
      cleanValue = cleanValue.replace(/,/g, '')

      deal.br_money = (parseFloat(cleanValue) * parseFloat(deal.sale_price)) / 100
      recalculateReferrals(deal.br_money)
    }

    const calculateBrPercentage = (e) => {
      let cleanValue = e.target.value.replace('$ ', '')
      cleanValue = cleanValue.replace(/,/g, '')
      deal.br_percentage = (parseFloat(cleanValue) * 100) / parseFloat(deal.sale_price)
      recalculateReferrals(cleanValue)
    }

    const addReferral = async () => {
      const isValid = await referral_v$.value.$validate()
      if (!isValid) return
      deal.referrals.push(referralForm.value)
      cleanReferralForm()
      calculateDealValues()
    }
    const removeReferral = (index) => {
      deal.referrals.splice(index, 1)
      calculateDealValues()
    }
    const cleanReferralForm = () => {
      referralForm.value = {
        name: '',
        type: 'percentage',
        fee_money: '',
        fee_percentage: '',
      }
      referral_v$.value.$reset()
    }

    const addAssistant = async () => {
      const isValid = await assistant_v$.value.$validate()
      if (!isValid) return
      deal.assistants.push(assistantForm.value)
      cleanAssistantForm()
      calculateDealValues()
    }
    const removeAssistant = (index) => {
      deal.assistants.splice(index, 1)
      calculateDealValues()
    }
    const cleanAssistantForm = () => {
      assistantForm.value = {
        name: '',
        type: 'percentage',
        fee_money: '',
        fee_percentage: '',
      }
      assistant_v$.value.$reset()
    }

    const calculatePercentage = (e, source) => {
      switch (source) {
        case 'referral': {
          referralForm.value.fee_percentage = ((parseFloat(e.target.value) * 100) / parseFloat(deal.br_money)).toFixed(
            1
          )
          break
        }
        case 'assistant': {
          assistantForm.value.fee_percentage = (
            (parseFloat(e.target.value) * 100) /
            parseFloat(deal.left_to_split_2)
          ).toFixed(1)
          break
        }
      }
    }

    const calculateMoney = (e, source) => {
      switch (source) {
        case 'referral': {
          referralForm.value.fee_money = ((parseFloat(e.target.value) * parseFloat(deal.br_money)) / 100).toFixed(2)
          break
        }
        case 'assistant': {
          assistantForm.value.fee_money = (
            (parseFloat(e.target.value) * parseFloat(deal.left_to_split_2)) /
            100
          ).toFixed(2)
          break
        }
      }
    }

    const updateDeal = async () => {
      const isValid = await deal_v$.value.$validate()
      if (!isValid) return
      try {
        calculateDealValues()
        const response = await DealService.updateDeal(props.deal.id, deal)
        emit('close')
        emit('onUpdate')
        resetForm()
        toast.add({ severity: 'success', summary: 'Deal updated with success!', detail: '', life: 3000 })
      } catch (error) {
        console.log(error)
        toast.add({ severity: 'error', summary: 'Error', detail: error.errors, life: 3000 })
      }
    }

    const error = (entity) => {
      toast.add({
        severity: 'error',
        summary: 'Something went wrong!',
        detail: `Unable to create ${entity}`,
        life: 3000,
      })
    }

    const resetForm = () => {
      selectedProperty.value = {}
      selectedClient.value = {}
      selectedNotary.value = {}
      selectedAttorney.value = {}
      selectedAgency.value = {}
      selectedAgent.value = {}

      deal.referrals = []
      deal.assistants = []

      Object.assign(deal, new Deal())

      deal_v$.value.$reset()
      referral_v$.value.$reset()
      assistant_v$.value.$reset()
    }

    return {
      selectedRole,
      deal,
      selectAgency,
      selectAttorney,
      selectNotary,
      selectedProperty,
      selectedClient,
      selectedAgent,
      selectedAgency,
      selectedNotary,
      selectClient,
      selectProperty,
      selectAgent,
      selectedAttorney,
      refreshClients,
      refreshProperties,
      dealStatus,
      sources,
      addReferral,
      referralForm,
      removeReferral,
      addAssistant,
      assistantForm,
      removeAssistant,
      calculateMoney,
      calculatePercentage,
      updateDeal,
      getNewProperty,
      getNewClient,
      error,
      salePriceKeyup,
      calculateDealValues,
      calculateBrMoney,
      calculateBrPercentage,
        deal_v$,
      referral_v$,
      assistant_v$,
    }
  },
}
</script>
