import axios from '@/config/axios'

class PropertyService {
  async fetchStates() {
    try {
      const resp = await axios.get('/states')
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchAllPropertyStatus() {
    try {
      const resp = await axios.get('/property_status')
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchProperties() {
    try {
      const resp = await axios.get('/properties')
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchProperty(id) {
    try {
      const resp = await axios.get(`/properties/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async createProperty(property) {
    try {
      const resp = await axios.post('/properties', property)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async updateProperty(id, property) {
    try {
      const resp = await axios.put(`/properties/${id}`, property)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async deleteProperty(id) {
    try {
      const resp = await axios.delete(`/properties/${id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }
}

export default new PropertyService()
