<template>
  <div class="row w-100 m-0">
    <div class="col-12">
      <h2>Deals</h2>
    </div>
    <div class="col-12">
      <Toolbar>
        <template #start>
          <button class="btn btn-info me-2" @click="showCreate = true"><i class="fas fa-plus me-2"></i>New Deal</button>
          <button class="btn btn-success me-2" data-bs-toggle="collapse" data-bs-target="#collapseStats" type="button">
            <i class="fas fa-chart-line me-2"></i>Stats
          </button>
          <button class="btn btn-primary" data-bs-toggle="collapse" data-bs-target="#collapseFilters" type="button">
            <i class="fas fa-filter me-2"></i>Filters
          </button>
        </template>
      </Toolbar>
    </div>

    <div class="col-12">
      <div class="collapse show" id="collapseFilters">
        <DealFilters></DealFilters>
      </div>
    </div>

    <div class="col-12">
      <div class="collapse" id="collapseStats">
        <div class="card card-body text-end" v-if="stats != null">
          <div class="row">
            <div class="col-3"></div>
            <div class="col-3 text-uppercase fw-600 text-danger">Maybe</div>
            <div class="col-3 text-uppercase fw-600 text-warning">Escrow</div>
            <div class="col-3 text-uppercase fw-600 text-success">Closed</div>
          </div>
          <div class="row mt-2">
            <div class="col-3 badge bg-primary" style="padding: 12px">My Records</div>
            <div class="col-3 text-danger border-bottom">{{ formatter.format(stats.own.maybe) }}</div>
            <div class="col-3 text-warning border-bottom">{{ formatter.format(stats.own.escrow) }}</div>
            <div class="col-3 text-success border-bottom">{{ formatter.format(stats.own.closed) }}</div>
          </div>
          <div class="row mt-2" v-for="(team, index) in stats.total_teams" :key="index">
            <div class="col-3 badge" style="padding: 12px" :class="getBadge(index)">{{ team.team }}</div>
            <div class="col-3 text-danger border-bottom">{{ formatter.format(team.maybe) }}</div>
            <div class="col-3 text-warning border-bottom">{{ formatter.format(team.escrow) }}</div>
            <div class="col-3 text-success border-bottom">{{ formatter.format(team.closed) }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mt-3">
      <TabView>
        <TabPanel>
          <template #header>
            <i class="pi pi-briefcase me-2"></i>
            <span>Maybe</span>
          </template>
          <MaybeDeals :maybeDeals="maybeDeals" :loading="loading" @editDeal="editDeal"></MaybeDeals>
        </TabPanel>
        <TabPanel>
          <template #header>
            <i class="pi pi-briefcase me-2"></i>
            <span>Escrow</span>
          </template>
          <EscrowDeals :escrowDeals="escrowDeals" :loading="loading" @editDeal="editDeal"></EscrowDeals>
        </TabPanel>
        <TabPanel>
          <template #header>
            <i class="pi pi-briefcase me-2"></i>
            <span>Closed</span>
          </template>
          <ClosedDeals :closedDeals="closedDeals" :loading="loading" @editDeal="editDeal"></ClosedDeals>
        </TabPanel>
        <TabPanel>
          <template #header>
            <i class="pi pi-briefcase me-2"></i>
            <span>Lost</span>
          </template>
          <LostDeals :lostDeals="lostDeals" :loading="loading" @editDeal="editDeal"></LostDeals>
        </TabPanel>
      </TabView>
    </div>
  </div>
  <ModalCreateDeal :showModal="showCreate" @close="showCreate = false" @onSave="fetchDeals"> </ModalCreateDeal>
  <ModalEditDeal
    :showModal="showEdit"
    :deal="selectedDeal"
    @close="showEdit = false"
    @onUpdate="fetchDeals"
  ></ModalEditDeal>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, computed } from 'vue'
import { onMounted, watch } from '@vue/runtime-core'

import DealsService from '../../services/deal.service'

import ModalCreateDeal from './components/ModalCreateDeal.vue'
import ModalEditDeal from './components/ModalEditDeal.vue'
import ModalProperty from '../../components/modals/ModalProperty.vue'

// PrimeVue Components
import Toolbar from 'primevue/toolbar'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'

import EscrowDeals from './components/EscrowDeals.vue'
import MaybeDeals from './components/MaybeDeals.vue'
import LostDeals from './components/LostDeals.vue'
import ClosedDeals from './components/ClosedDeals.vue'
import DealFilters from '../../components/DealFilters.vue'

export default {
  components: {
    ModalCreateDeal,
    ModalEditDeal,
    ModalProperty,
    DataTable,
    Column,
    TabView,
    TabPanel,
    Button,
    EscrowDeals,
    MaybeDeals,
    ClosedDeals,
    LostDeals,
    Toolbar,
    DealFilters,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const deals = computed(() => store.getters['deals/filteredDeals'])
    const maybeDeals = computed(() => deals.value.filter((deal) => deal.deal_status.name === 'maybe'))
    const escrowDeals = computed(() => deals.value.filter((deal) => deal.deal_status.name === 'escrow'))
    const closedDeals = computed(() => deals.value.filter((deal) => deal.deal_status.name === 'closed'))
    const lostDeals = computed(() => deals.value.filter((deal) => deal.deal_status.name === 'lost'))
    const year = computed(() => store.state.deals.year)
    const filter = computed(() => store.state.deals.filter)

    const stats = ref(null)

    const showCreate = ref(false)
    const showEdit = ref(false)
    const loading = ref(false)

    const selectedRole = computed(() => store.state.selectedRole)
    const selectedTeamRole = computed(() => store.state.selectedTeamRole)

    const selectedDeal = ref({})

    onMounted(() => {
      store.commit('deals/setYear', 2022)
      store.commit('deals/setFilter', 'created_at')
    })

    watch(
      [selectedRole, selectedTeamRole],
      () => {
        fetchDeals()
      },
      { immediate: true }
    )

    watch(
      [selectedRole, selectedTeamRole, year, filter],
      () => {
        fetchStats()
      },
      { immediate: true }
    )

    async function fetchStats() {
      stats.value = await DealsService.fetchDealStats(
        selectedRole.value?.id,
        selectedTeamRole.value === null ? "" : Object.keys(selectedTeamRole.value).length === 0 ? "" : selectedTeamRole.value?.id,
        year.value,
        filter.value
      )
    }

    async function fetchDeals() {
      loading.value = true
      await store.dispatch('deals/fetchDeals')
      loading.value = false
    }

    const editDeal = (deal) => {
      selectedDeal.value = deal
      showEdit.value = true
    }

    const formatter = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

    const getBadge = (index) => {
      const badgets = ['bg-success', 'bg-danger', 'bg-secondary']
      return badgets[index]
    }

    return {
      deals,
      maybeDeals,
      escrowDeals,
      closedDeals,
      lostDeals,
      stats,
      editDeal,
      selectedDeal,
      selectedRole,
      loading,
      showCreate,
      showEdit,
      formatter,
      getBadge,
      fetchDeals,
    }
  },
}
</script>
