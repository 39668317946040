<template>
  <header class="layout-topbar">
        <!-- LOGO -->
        
        <button class="p-link layout-menu-buton layout-topbar-button" @click="toggleSidebar">
          <i class="pi pi-bars"></i>
        </button>

        <button class="p-link layout-topbar-menu-button layout-topbar-button"
        v-styleclass="{selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein',
        leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
        <i class="pi pi-ellipsis-v"></i>
        </button>
        <ul class="layout-topbar-menu hidden lg:flex origin-top" id="list">
            <li>
              <button class="p-link layout-topbar-button">
                <i class="pi pi-calendar"></i>
                <span>Events</span>
              </button>
            </li>
            <li>
              <button class="p-link layout-topbar-button">
                <i class="pi pi-cog"></i>
                <span>Settings</span>
              </button>
            </li>
            <li>
                <div class="dropdown w-100" style="margin: auto">
                  <div class="dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                    <button class="p-link layout-topbar-button dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="">
                      <i class="pi pi-user"></i>
                      <span>Profile</span>
                    </button>
                </div>
                <div class="dropdown-menu w-100 mt-2" aria-labelledby="dropdownMenuButton">
                  <span>{{ user.name }} {{ user.last_name }}</span>
                  <div class="dropdown-item" @click="logout()" >Close Session</div>
                </div>
              </div>
            </li>
		</ul>


        <!-- <button
          @click="toggleSidebar"
          type="button"
          class="btn btn-sm px-3 font-size-24 header-item waves-effect"
          id="vertical-menu-btn"
        >
          <i class="ri-menu-2-line align-middle"></i>
        </button> -->

      <!-- <div class="d-flex">
        <div class="dropdown d-inline-block d-lg-none ml-2">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            id="page-header-search-dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="ri-search-line"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
            aria-labelledby="page-header-search-dropdown"
          >
            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search ..." />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="submit">
                      <i class="ri-search-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            @click="initFullScreen"
          >
            <i class="ri-fullscreen-line"></i>
          </button>
        </div>
        
          <div class="dropdown w-100" style="margin: auto">
          <div class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> -->
            <!-- <div class="me-3" style="width:45px; height:45px; border-radius:50%; background-color: grey;"></div> -->
            <!-- <span>{{ user.name }} {{ user.last_name }}</span>
          </div>
          <div class="dropdown-menu w-100 mt-2" aria-labelledby="dropdownMenuButton">
            <div class="dropdown-item" @click="logout()" >Close Session</div>
          </div>
        </div>
      </div> -->
  </header>
</template>


<script>

import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    const user = ref(store.getters.user)

    const logout = async () => {
        const response = await store.dispatch('logout');
        if(response.message === 'Loggued Out'){
          // location.reload()

          router.push('login')
          setTimeout(() => {
            store.dispatch('deleteCredentials')
          }, 100)
        }else{
          // Alerta de que hubo error al cerrar sesión
        }
    }

    const toggleSidebar = () => {
      document.querySelector('.sidebar').classList.toggle('.isOpened')
    } 

    const onMenuToggle =(event) => {
      this.$emit('menu-toggle', event);
    }

    const onTopbarMenuToggle = (event) => {
      this.$emit('topbar-menu-toggle', event);
    }



    const initFullScreen = () => {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    }


    return{
      user,
      logout,
      toggleSidebar,
      initFullScreen,
      onMenuToggle,
      onTopbarMenuToggle,
    }
  },
}
</script>

<style lang="scss" scoped>
.notify-item {
  .active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}
.btn-toggle {
  background: transparent;
  border: none;
  i{
    color: white;
    font-size: 24px;
  }
  &:active i {
    font-size: 20px;
  }

  .hidden {
    display: none !important;
  }
}
</style>
