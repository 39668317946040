<template>
  <div class="dropdown w-100">
    <button class="form-control dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
      <div>{{ selectedProperty.name ? selectedProperty.name.length > 35 ? selectedProperty.name.substr(0, 35) + '...' : selectedProperty.name : 'Select a Property' }}</div>
      <i class="fas fa-chevron-down"></i>
    </button>
    <div class="dropdown-menu w-100 custom-shadow" aria-labelledby="dropdownMenuButton1">
      <div class="w-100 p-2">
        <input type="text" class="form-control" placeholder="Search property..." @keyup="filterProperties($event)">
      </div>
      <div class="dropdown-item-list">
        <div class="dropdown-item" v-for="(property, index) in filteredProperties" :key="index" @click="selectProperty(index)">
          <i class="fas fa-university"></i>
          <span>{{ property.name }} {{ property.last_name }} </span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import { ref, onMounted, watch, computed } from  'vue'

  import PropertyService from '../../services/property.service'
import { useStore } from 'vuex'
  export default {
    props: ['property', 'refresh'],
    emits: ['selectProperty'],
    setup(props, {emit}) {
      const store = useStore()
      const role = computed(() => store.state.selectedRole)
      var properties = ref([])
      var filteredProperties = ref([])
      var selectedProperty = ref(props.property)

      onMounted(() => {
        getProperties()
      })

      const getProperties = async () => {
        properties.value = await PropertyService.fetchProperties()
        filteredProperties.value = properties.value
      }

      const filterProperties = (e) => {
        let filtered = properties.value.filter((property) => {
          if(property.name.toUpperCase().includes(e.target.value.toUpperCase())){
            return property;
          }
        })
        filteredProperties.value = filtered
      }

      const selectProperty = (index) => {
        selectedProperty.value = filteredProperties.value[index]
        emit('selectProperty', filteredProperties.value[index])
      }

      watch(
        props,
        () => {
          if(props.refresh){
            getProperties()
          }
          selectedProperty.value = props.property
        }
      )

      return{
        filteredProperties,
        selectedProperty,
        selectProperty,
        filterProperties,
      }
    },
  }
</script>

<style lang="scss">
.dropdown-toggle.form-control{
  min-width: 220px;
}
  .dropdown-item-list{
    max-height: 200px;
    overflow-y: auto;
    .dropdown-item{
      display: flex;
      align-items: center;
      white-space: initial;
      line-height: initial;
      cursor: pointer;
      padding: 0.5rem 1rem;
      transition: .3s;
      &:hover{
        background-color: aliceblue;
      }
      
      i{
        margin-right: 15px;
      }
    }
  }
</style>