import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//Boostrap
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import './assets/scss/_variables.scss'

import 'primeflex/primeflex.css';


//PrimeVue
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primeicons/primeicons.css'

import StyleClass from 'primevue/styleclass';
import RadioButton from 'primevue/radiobutton';
import TabMenu from 'primevue/tabmenu';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Toolbar from 'primevue/toolbar';
import ToggleButton from 'primevue/togglebutton';


//sidebar
import VueSidebarMenuAkahon from "vue-sidebar-menu-akahon";

//SweetAlert
import 'sweetalert2/dist/sweetalert2.css'

//Custom Styles
//import './assets/scss/app.scss'
//import "@/assets/scss/app.scss";
import "@/assets/scss/styles-dashboard.scss"
import "@/assets/scss/_topbar.scss"
import "@/assets/scss/_mixins.scss"
import "@/assets/scss/_content.scss"
import "@/assets/scss/_responsive.scss"
import "@/assets/scss/custom/components/_card.scss"

import { parseStringStyle } from '@vue/shared';



const app = createApp(App)
app.use(PrimeVue)
app.use(ToastService);
app.use(ConfirmationService);
app.use(store).use(router).mount('#app')

app.directive('styleclass', StyleClass);
app.component('RadioButton', RadioButton);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);



app.component('vue-sidebar-menu-akahon', VueSidebarMenuAkahon);
//testing
// import '../tests/auth_service.test'
// import '../tests/property_service.test'
// import '../tests/escrow_agency_service.test'
// import '../tests/notary_service.test'
// import '../tests/attorney_service.test'
// import '../tests/client_service.test'
// import '../tests/source_service.test'
// import '../tests/team_service.test'
// import '../tests/sale_volume_service.test'
// import '../tests/deal_service.test'
