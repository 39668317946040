<template>
  <div class="dropdown w-100">
    <button class="form-control dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
      <div>{{ selectedClient.name ? selectedClient.name + ' ' +selectedClient.last_name : 'Select a Client' }}</div>
      <i class="fas fa-chevron-down"></i>
    </button>
    <div class="dropdown-menu w-100 custom-shadow" aria-labelledby="dropdownMenuButton1">
      <div class="w-100 p-2">
        <input type="text" class="form-control" placeholder="Search client..." @keyup="filterClients($event)">
      </div>
      <div class="dropdown-item-list">
        <div class="dropdown-item" v-for="(client, index) in filteredClients" :key="index" @click="selectClient(index)">
          <i class="fas fa-university"></i>
          <span>{{ client.name }} {{ client.last_name }} </span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import { ref, onMounted, watch, computed } from  'vue'

  import ClientService from '../../services/client.service'
import { useStore } from 'vuex'
  export default {
    props: ['client', 'refresh'],
    emits: ['selectClient'],
    setup(props, {emit}) {
      const store = useStore()
      const role = computed(() => store.state.selectedRole)
      var clients = ref([])
      var filteredClients = ref([])
      var selectedClient = ref(props.client)

      onMounted(() => {
        getClients()
      })

      const getClients = async () => {
        clients.value = await ClientService.fetchAll(role.value.id)
        filteredClients.value = clients.value
      }

      const filterClients = (e) => {
        let filtered = clients.value.filter((client) => {
          if(client.name.toUpperCase().includes(e.target.value.toUpperCase())){
            return client;
          }
        })
        filteredClients.value = filtered
      }

      const selectClient = (index) => {
        selectedClient.value = filteredClients.value[index]
        emit('selectClient', filteredClients.value[index])
      }

      watch(
        props,
        () => {
          if(props.refresh){
            getClients()
          }
          selectedClient.value = props.client
        }
      )

      return{
        filteredClients,
        selectedClient,
        selectClient,
        filterClients,
      }
    },
  }
</script>

<style lang="scss">
.dropdown-toggle.form-control{
  min-width: 220px;
}
  .dropdown-item-list{
    max-height: 200px;
    overflow-y: auto;
    .dropdown-item{
      display: flex;
      align-items: center;
      white-space: initial;
      line-height: initial;
      cursor: pointer;
      padding: 0.5rem 1rem;
      transition: .3s;
      &:hover{
        background-color: aliceblue;
      }
      
      i{
        margin-right: 15px;
      }
    }
  }
</style>