<template>
  <div>
    <div class="modal fade" id="modalProperty" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalPropertyLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="modalPropertyLabel">New Property</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-start">
            <div class="row">
              <div class="col-12">
                <h5>Property information</h5>
              </div>
              <div class="row mt-4" >
                <div class="col-8">
                  <div class="form-group">
                    <label for="">Property Name</label>
                    <input type="text" class="form-control" v-model="propertyForm.name" >
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label for=""># MLS</label>
                    <input type="text" class="form-control" v-model="propertyForm.mls" >
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="">Description</label>
                    <input type="text" class="form-control" v-model="propertyForm.description" >
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label for="">State</label>
                    <div class="dropdown w-100">
                      <button class="form-control dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <div>{{ propertyForm.state.name !== '' ? propertyForm.state.name : 'Select State' }}</div>
                        <i class="fas fa-chevron-down"></i>
                      </button>
                      <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                        <div class="w-100 p-2">
                          <input type="text" class="form-control" placeholder="Search state..." @keyup="filterStates($event)">
                        </div>
                        <div class="dropdown-item-list">
                          <div class="dropdown-item" v-for="(state, index) in filteredStates" :key="index" @click="selectState(index)">
                            <i class="fas fa-map-marked-alt"></i>
                            <span>{{ state.name }} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="">City</label>
                    <input type="text" class="form-control" v-model="propertyForm.city" >
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label for="">Address</label>
                    <input type="text" class="form-control" v-model="propertyForm.address" >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="saveProperty()">Save Property</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { ref, onMounted } from  'vue'

  import PropertyService from '../../services/property.service'
  export default {
    setup(props, {emit}) {
      const store = useStore()
      const router = useRouter()

      var states = ref(store.getters.states)
      var filteredStates = ref(store.getters.states)
      var selectedState = ref({})

      var propertyForm = ref({
        name: '',
        description: '',
        address: '',
        mls: '',
        state: {
          name: '',
          id: ''
        },
        state_id: '',
        city: '',
        status_id: 1
      })

      onMounted(() => {
      })

      const filterStates = (e) => {
        let filtered = states.value.filter((state) => {
          if(state.name.toUpperCase().includes(e.target.value.toUpperCase())){
            return state;
          }
        })

        filteredStates.value = filtered
      }

      const selectState = (index) => {
        propertyForm.value.state = filteredStates.value[index];
        propertyForm.value.state_id = filteredStates.value[index].id;
      }

      const saveProperty = async () => {
        const response = await PropertyService.createProperty(propertyForm.value)
        if(response.id){
          store.dispatch('fetchSates')
        }
      }

      return{
        filteredStates,
        selectedState,
        selectState,
        filterStates,
        propertyForm,
        saveProperty
      }
    },
  }
</script>

<style lang="scss">
.dropdown-item-list{
  max-height: 250px;
  overflow-y: auto;
  .dropdown-item{
    display: flex;
    align-items: center;
    white-space: initial;
    line-height: initial;
    cursor: pointer;
    padding: 0.5rem 1rem;
    transition: .3s;
    &:hover{
      background-color: aliceblue;
    }
    
    i{
      margin-right: 15px;
    }
  }
}

.referral-item{
  line-height: initial;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>