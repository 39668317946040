import { createStore } from 'vuex'
import AuthService from '@/services/auth.service'
import usersStore from './users.store'
import dealsStore from './deals.store'

import PropertyService from '../services/property.service'
import DealService from '../services/deal.service'
import SourceService from '../services/source.service'
import TeamService from '../services/team.service'

const initialState = JSON.parse(localStorage.getItem('user'))
const selectedTeam = JSON.parse(localStorage.getItem('selectedTeam'))
const selectedRole = JSON.parse(localStorage.getItem('selectedRole'))
const selectedTeamRole = JSON.parse(localStorage.getItem('selectedTeamRole'))
const states = JSON.parse(localStorage.getItem('states'))
const dealStatus = JSON.parse(localStorage.getItem('dealStatus'))
const sources = JSON.parse(localStorage.getItem('sources'))
const teams = JSON.parse(localStorage.getItem('teams'))
export default createStore({
  state: {
    loggedIn: initialState ? true : false,
    user: initialState ? initialState : null,
    selectedTeam: selectedTeam ? selectedTeam : null,
    selectedRole: selectedRole ? selectedRole : null,
    selectedTeamRole: selectedTeamRole ? selectedTeamRole : null,
    states: states ? states : null,
    dealStatus: dealStatus ? dealStatus : null,
    sources: sources ? sources : null,
    teams: teams ? teams : null,
  },
  mutations: {
    login(state, payload) {
      state.user = payload
      state.loggedIn = true
      localStorage.setItem('user', JSON.stringify(payload))
    },
    logout(state) {
      state.loggedIn = false
      state.user = {}
      state.selectedTeam = {}
      state.selectedRole = {}
      state.selectedTeamRole = {}
      state.states = {}
      state.dealStatus = {}
      state.sources = {}
      state.teams = {}
      localStorage.removeItem('user')
      localStorage.removeItem('selectedTeam')
      localStorage.removeItem('selectedRole')
      localStorage.removeItem('selectedTeamRole')
      localStorage.removeItem('states')
      localStorage.removeItem('dealStatus')
      localStorage.removeItem('sources')
      localStorage.removeItem('teams')
    },
    selectTeam(state, payload) {
      state.selectedTeam = payload
      localStorage.setItem('selectedTeam', JSON.stringify(payload))
    },
    selectRole(state, payload) {
      state.selectedRole = payload
      localStorage.setItem('selectedRole', JSON.stringify(payload))
    },
    selectTeamRole(state, payload) {
      state.selectedTeamRole = payload
      localStorage.setItem('selectedTeamRole', JSON.stringify(payload))
    },
    fetchStates(state, payload) {
      state.states = payload
      localStorage.setItem('states', JSON.stringify(payload))
    },
    fetchDealStatus(state, payload) {
      state.dealStatus = payload
      localStorage.setItem('dealStatus', JSON.stringify(payload))
    },
    fetchSources(state, payload) {
      state.sources = payload
      localStorage.setItem('sources', JSON.stringify(payload))
    },
    fetchTeams(state, payload) {
      state.teams = payload
      localStorage.setItem('teams', JSON.stringify(payload))
    },
  },
  actions: {
    async login({ commit }, user) {
      try {
        const response = await AuthService.login(user)
        if (response.data !== undefined) {
          commit('login', response.data)
          return Promise.resolve(response.data)
        } else {
          return Promise.resolve('ERROR')
        }
      } catch (error) {
        console.error(`Error en el login: ${error}`)
        return Promise.reject(error)
      }
    },
    async logout({ commit }) {
      try {
        const response = await AuthService.logout()
        if (response.data !== undefined) {
          // commit('logout')
          return Promise.resolve(response.data)
        } else {
          return Promise.resolve('ERROR')
        }
      } catch (error) {
        console.error(`Error en el login: ${error}`)
        return Promise.reject(error)
      }
    },
    deleteCredentials({ commit }){
      commit('logout')
    },
    selectTeam({ commit }, team) {
      commit('selectTeam', team)
      return Promise.resolve(team)
    },
    selectRole({ commit }, role) {
      commit('selectRole', role)
      return Promise.resolve(role)
    },
    selectTeamRole({ commit }, role) {
      commit('selectTeamRole', role)
      return Promise.resolve(role)
    },
    async fetchStates({ commit }) {
      try {
        const response = await PropertyService.fetchStates()
        if (response) {
          commit('fetchStates', response)
        } else {
          return Promise.resolve('ERROR')
        }
      } catch (error) {
        console.error(`Error while fetching states: ${error}`)
        return Promise.reject(error)
      }
    },
    async fetchDealStatus({ commit }) {
      try {
        const response = await DealService.fetchDealStatus()
        if (response) {
          commit('fetchDealStatus', response)
        } else {
          return Promise.resolve('ERROR')
        }
      } catch (error) {
        console.error(`Error while fetching deal status: ${error}`)
        return Promise.reject(error)
      }
    },
    async fetchSources({ commit }) {
      try {
        const response = await SourceService.fetchAll()
        if (response) {
          commit('fetchSources', response)
        } else {
          return Promise.resolve('ERROR')
        }
      } catch (error) {
        console.error(`Error while fetching deal sources: ${error}`)
        return Promise.reject(error)
      }
    },
    async fetchTeams({ commit }) {
      try {
        const response = await TeamService.fetchAll()
        if (response) {
          commit('fetchTeams', response)
        } else {
          return Promise.resolve('ERROR')
        }
      } catch (error) {
        console.error(`Error while fetching teams: ${error}`)
        return Promise.reject(error)
      }
    },
  },
  modules: {
    users: usersStore,
    deals: dealsStore,
  },
  getters: {
    user: (state) => {
      return state.user.user
    },
    selectedTeam: (state) => {
      return state.selectedTeam
    },
    selectedTeamRole: (state) => {
      return state.selectedTeamRole
    },
    userRoles: (state) => {
      return state.user.user?.roles
    },
    userTeam: (state) => {
      return state.user.user?.team
    },
    userTeamRoles: (state) => {
      return state.user.user?.team_roles
    },
    selectedRole: (state) => {
      return state.selectedRole
    },
    states: (state) => {
      return state.states
    },
    teams: (state) => {
      return state.teams
    },
    dealStatus: (state) => {
      return state.dealStatus
    },
    sources: (state) => {
      return state.sources
    },
  },
})
