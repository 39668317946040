export default {
  namespaced: true,
  state: {
    users: [],
    roles: [],
  },
  mutations: {
    SET_USERS(state, payload) {
      state.users = payload
    },
    SET_ROLES(state, payload) {
      state.roles = payload
    },
    ADD_USER(state, payload) {
      state.users.push(payload)
    },
    DELETE_USER(state, payload) {
      state.users = state.users.filter((u) => u.id != payload)
    },
    UPDATE_USER(state, payload) {
      state.users = state.users.map((u) => (u.id == payload.id ? payload : u))
    },
  },
}
