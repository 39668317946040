<template>
  <div class="dropdown w-100">
    <button class="form-control dropdown-toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <div>{{ selectedAgency.name ? selectedAgency.name : 'Select an Agency' }}</div>
      <i class="fas fa-chevron-down"></i>
    </button>
    <div class="dropdown-menu w-100 custom-shadow" aria-labelledby="dropdownMenuButton1">
      <div class="w-100 p-2">
        <input type="text" class="form-control" placeholder="Search agency..." @keyup="filterAgencies($event)">
      </div>
      <div class="dropdown-item-list">
        <div class="dropdown-item" v-for="(agency, index) in filteredAgencies" :key="index" @click="selectAgency(index)">
          <i class="fas fa-university"></i>
          <span>{{ agency.name }} </span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import { ref, onMounted } from  'vue'

  import EscrowAgencyService from '../../services/escrow_agency.service'
  export default {
    props: ['agency'],
    emits: ['selectAgency'],
    setup(props, {emit}) {

      var agencies = ref([])
      var filteredAgencies = ref([])
      var selectedAgency = ref(props.agency)

      onMounted(() => {
        getAgencies()
      })

      const getAgencies = async () => {
        agencies.value = await EscrowAgencyService.fetchAll()
        filteredAgencies.value = agencies.value
      }

      const filterAgencies = (e) => {
        let filtered = agencies.value.filter((agency) => {
          if(agency.name.toUpperCase().includes(e.target.value.toUpperCase())){
            return agency;
          }
        })
        filteredAgencies.value = filtered
      }

      const selectAgency = (index) => {
        emit('selectAgency', filteredAgencies.value[index])
        selectedAgency.value = filteredAgencies.value[index]
      }

      return{
        filteredAgencies,
        selectedAgency,
        selectAgency,
        filterAgencies,
      }
    },
  }
</script>

<style lang="scss">
.dropdown-toggle.form-control{
  min-width: 220px;
}
  .dropdown-item-list{
    max-height: 200px;
    overflow-y: auto;
    .dropdown-item{
      display: flex;
      align-items: center;
      white-space: initial;
      line-height: initial;
      cursor: pointer;
      padding: 0.5rem 1rem;
      transition: .3s;
      &:hover{
        background-color: aliceblue;
      }
      
      i{
        margin-right: 15px;
      }
    }
  }
</style>