export default class Deal {
  constructor(
    property_id,
    sale_type_id,
    deal_status_id,
    aeo,
    escrow_number,
    escrow_agency_id,
    deal_source_id,
    representing,
    coe,
    ext,
    count_down,
    notary_id,
    attorney_id,
    cooperation,
    special_conditions,
    client_id,
    sale_price,
    br_percentage,
    br_money,
    closing_cord_percentage,
    agent_split_percentage,
    agent_final,
    referrals,
    assistants,
    team_id,
    agent_id,
    ref_fee_total,
    left_to_split_1,
    team_money,
    left_to_br,
    closing_cord_money,
    left_to_split_2,
    agent_split_money,
    assistants_fee_total,
    left_to_split_3,
    head
  ) {
    this.property_id = property_id
    this.sale_type_id = sale_type_id
    this.deal_status_id = deal_status_id
    this.aeo = aeo
    this.escrow_number = escrow_number
    this.escrow_agency_id = escrow_agency_id
    this.deal_source_id = deal_source_id
    this.representing = representing
    this.coe = coe
    this.ext = ext
    this.count_down = count_down
    this.notary_id = notary_id
    this.attorney_id = attorney_id
    this.cooperation = cooperation
    this.special_conditions = special_conditions
    this.client_id = client_id
    this.sale_price = sale_price
    this.br_percentage = br_percentage
    this.br_money = br_money 
    this.closing_cord_percentage = closing_cord_percentage
    this.agent_split_percentage = agent_split_percentage
    this.agent_final = agent_final 
    this.referrals = referrals || []
    this.assistants = assistants || []
    this.team_id = team_id
    this.agent_id = agent_id
    this.ref_fee_total = ref_fee_total
    this.left_to_split_1 = left_to_split_1
    this.team_money = team_money
    this.left_to_br = left_to_br
    this.closing_cord_money = closing_cord_money
    this.left_to_split_2 = left_to_split_2
    this.agent_split_money = agent_split_money
    this.assistants_fee_total = assistants_fee_total
    this.left_to_split_3 = left_to_split_3
    this.head = head
  }
}
